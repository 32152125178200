import { useCallback, useMemo, useState } from 'react';
import * as z from 'zod';
import { buildImpactLocalstorageKey } from '../lib/localStorage';

const LOCAL_STORAGE_GRID_KEY = 'grid';

// The schema for parsing what's in the local storage
const localStorageStateSchema = z.object({
    columnVisibilities: z.record(z.boolean()).optional().default({}),
    hiddenColumns: z.array(z.string()).optional(), // Legacy, no default value
    savedFilterId: z.number().optional(),
});

// The final parsed type that we are interested in
type GridTableLocalStorageState = Omit<z.infer<typeof localStorageStateSchema>, 'hiddenColumns'>;

const defaultLocalStorageState = localStorageStateSchema.parse({} satisfies z.input<typeof localStorageStateSchema>);

const castLocalStorageState = (
    value: unknown,
    defaultValue: GridTableLocalStorageState
): GridTableLocalStorageState => {
    const result = localStorageStateSchema.safeParse(value);
    if (result.success) {
        const { hiddenColumns, columnVisibilities: columnVisibilities, ...rest } = result.data;
        return {
            ...rest,
            columnVisibilities:
                hiddenColumns !== undefined
                    ? Object.fromEntries(
                          hiddenColumns
                              .slice()
                              .sort()
                              .map((k) => [k, false])
                      )
                    : columnVisibilities,
        };
    } else {
        return defaultValue;
    }
};

const deserializeLocalStorageState = (
    value: string | null,
    defaultValue: GridTableLocalStorageState
): GridTableLocalStorageState => {
    if (value !== null) {
        let parsed;
        try {
            parsed = JSON.parse(value);
        } catch (syntaxError) {
            return defaultValue;
        }
        return castLocalStorageState(parsed, defaultValue);
    } else {
        return defaultValue;
    }
};

const serializeLocalStorageState = (initialState: GridTableLocalStorageState): string => JSON.stringify(initialState);

const makeFullDefaultValue = (defaultValue: GridTableLocalStorageState): GridTableLocalStorageState => {
    return {
        columnVisibilities: defaultValue.columnVisibilities ?? defaultLocalStorageState.columnVisibilities,
        savedFilterId: defaultValue.savedFilterId ?? defaultLocalStorageState.savedFilterId,
    };
};

// Hook

export const useGridTableLocalStorageState = (
    gridKey: string,
    defaultValue: GridTableLocalStorageState = defaultLocalStorageState
): [GridTableLocalStorageState, (newLocalStorageState: GridTableLocalStorageState) => void] => {
    const key = useMemo(() => buildImpactLocalstorageKey(LOCAL_STORAGE_GRID_KEY, gridKey), [gridKey]);
    const [fullDefaultValue] = useState(() => makeFullDefaultValue(defaultValue));
    const [localStorageState, setLocalStorageState] = useState(() =>
        deserializeLocalStorageState(window.localStorage.getItem(key), fullDefaultValue)
    );

    const setLocalStorageStateCallback = useCallback(
        (newInitialState: GridTableLocalStorageState) => {
            window.localStorage.setItem(key, serializeLocalStorageState(newInitialState));
            setLocalStorageState(deserializeLocalStorageState(window.localStorage.getItem(key), fullDefaultValue));
        },
        [key, setLocalStorageState, fullDefaultValue]
    );

    return [localStorageState, setLocalStorageStateCallback];
};
