import {
    ActivityReadDTO,
    FacilityCode,
    IS37ChangeRequestCreateDTO,
    IS37InterventionReadDTO,
    IS37ReadDTO,
    IS37UpdateDTO,
    LocationDTO,
    PersonReadDTO,
    PersonRichReadDTO,
} from '../../../api/dto';

export const isIs37LateSubmission = (is37: Pick<IS37UpdateDTO, 'startDate'>): boolean => {
    return (
        is37.startDate !== null &&
        new Date(is37.startDate).getTime() < new Date(Date.now() + 7 * 60 * 60 * 24 * 1000).getTime()
    );
};

export const areIs37LocationsPartOfActivityFacility = ({
    activity,
    locations,
}: Pick<IS37ReadDTO, 'activity' | 'locations'>): boolean =>
    locations.every((location) => (location.facility?.code ?? FacilityCode.SURFACE) === activity.facility?.code);
export const mapIs37ToUpdateDTO = (is37: any, isCreation: boolean = false): IS37UpdateDTO & IS37ReadDTO => {
    const { activity, ...is37WithoutActivity } = is37 as any;
    return isCreation ? is37 : is37WithoutActivity;
};

export const makeIs37DefaultValuesFromLinkedActivity = (
    linkedActivity: ActivityReadDTO,
    loggedUser: PersonRichReadDTO
) => {
    return {
        activity: linkedActivity,
        responsible: loggedUser,
        locations:
            linkedActivity?.locations
                ?.map(({ location }) => location)
                .filter((location): location is LocationDTO => location !== null) ?? [],
        availableActions: {
            attachable: false,
            canClone: false,
        },
        workTypes: [],
        sensors: [],
    } satisfies Partial<IS37ReadDTO>;
};
export const makeIs37DefaultValuesFromClonedIs37 = (
    is37ReadDTO: IS37ReadDTO,
    activity: ActivityReadDTO,
    loggedUser: PersonRichReadDTO
) => {
    const { id, version, ...rest } = is37ReadDTO;
    return {
        ...rest,
        activity,
        changeRequests: [],
        disablement: null,
        recommissioning: null,
        responsible: loggedUser,
        sensors: [],
        workOrderId: null,
    } satisfies Omit<IS37ReadDTO, 'id' | 'version'>;
};
export const makeIs37Values = (is37ReadDTO: IS37ReadDTO, currentUser: PersonReadDTO): IS37ReadDTO => {
    const makeInterventionDefaultValues = (): IS37InterventionReadDTO => ({
        company: null,
        doneBy: currentUser,
        inputDate: null,
        interventionDate: new Date().toISOString(),
        notes: null,
        operator: null,
    });
    return {
        ...is37ReadDTO,
        disablement: is37ReadDTO.disablement ?? makeInterventionDefaultValues(),
        recommissioning: is37ReadDTO.recommissioning ?? makeInterventionDefaultValues(),
    };
};

export const makeIS37ChangeRequestDefaultValues = (is37: IS37ReadDTO) => {
    return {
        newStartDate: is37.startDate,
        newEndDate: is37.endDate,
        newRecommissioningEnd: is37.recommissioningEnd,
        newRecommissioningStart: is37.recommissioningStart,
    } satisfies Partial<IS37ChangeRequestCreateDTO>;
};
