import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from 'mdi-material-ui';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const uiSearchForm = {
    type: UiSchemaType.PANEL,
    label: 'Search parameters',
    elements: [
        {
            type: UiSchemaType.LAYOUT_HORIZONTAL,
            elements: [
                {
                    type: UiSchemaType.LAYOUT_VERTICAL,
                    elements: [
                        {
                            type: UiSchemaType.AUTOCOMPLETE,
                            label: 'Facility',
                            path: 'facility',
                            typeProperties: {
                                fetchOptions: () => new Promise((resolve) => resolve([])),
                            },
                        },
                        {
                            type: UiSchemaType.INPUT,
                            label: 'Participant',
                            path: 'participant',
                        },
                        {
                            type: UiSchemaType.INPUT,
                            label: 'Activity',
                            path: 'activity',
                        },
                        {
                            type: UiSchemaType.DATE,
                            label: 'To',
                            path: 'to',
                        },
                    ],
                },
                {
                    type: UiSchemaType.LAYOUT_VERTICAL,
                    elements: [
                        {
                            type: UiSchemaType.AUTOCOMPLETE,
                            label: 'Access Point',
                            path: 'accessPoint',
                            typeProperties: {
                                fetchOptions: () => new Promise((resolve) => resolve([])),
                            },
                        },
                        {
                            type: UiSchemaType.INPUT,
                            label: 'E-group',
                            path: 'egroup',
                        },
                        {
                            type: UiSchemaType.DATE,
                            label: 'From',
                            path: 'from',
                        },
                    ],
                },
            ],
        },
    ],
} as TFormElement;

const searchFormSchema = z.object({
    facility: z.string(),
    accessPoint: z.string(),
    participant: z.string(),
    egroup: z.string(),
    activity: z.string(),
    from: z.date(),
    to: z.date(),
});

interface AccessControlSearchFormProps {}
export const AccessControlSearchForm: React.FC<AccessControlSearchFormProps> = () => {
    const form = useForm({
        mode: 'onSubmit',
        resolver: zodResolver(searchFormSchema),
    });

    return <Box></Box>; //<FormGenerator form={form} rootElement={uiSearchForm} />;
};
