import { ArrowForwardIosSharp, Task } from '@mui/icons-material';
import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    AccordionSummaryProps,
    Alert,
    Box,
    Card,
    CardActionArea,
    CardHeader,
    Chip,
    LinearProgress,
    Stack,
    styled,
    Typography,
} from '@mui/material';
import { AccordionProps } from '@mui/material/Accordion/Accordion';
import { blue, green, grey, orange, red } from '@mui/material/colors';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { DocumentType, PersonReferenceDTO, TaskDTO } from '../../api/dto';
import { addTaskAssignees } from '../../api/task';
import { HttpCode } from '../../api/types';
import { isApiError } from '../../api/utils';
import { useAdminRole } from '../../hooks/useAdminRole';
import { useDeviceType } from '../../hooks/useDeviceType';
import { formatDateTime } from '../../lib/date';
import { artificialDelay } from '../../lib/delay';
import { translateApiError, translateTask, translateTaskType } from '../../lib/language';
import { DocumentDrawerContext } from '../documents/DocumentDrawerStructure';
import { TaskManageButton } from '../documents/TaskManageButton';
import { ActionKind, DEFAULT_ACTION_KIND, TASK_CONFIGURATION } from './TaskTypeConfiguration';
import { useTaskCanBeCompleted } from './useTaskCanBeCompleted';
import { useTaskIsImportant } from './useTaskIsImportant';
import { useTasks } from './useTasks';

// Customization taken from https://mui.com/material-ui/react-accordion/#customization

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:before': {
            display: 'none',
        },
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface CustomCardActionAreaProps {
    onClick: () => void;
    readOnly: boolean;
    disabled: boolean;
    children: React.ReactNode;
}

// CardActionArea['disabled'] is not good because it also disables the content
const CustomCardActionArea: React.FC<CustomCardActionAreaProps> = ({ onClick, readOnly, disabled, children }) => {
    return !readOnly ? (
        <CardActionArea disabled={disabled} onClick={() => onClick()}>
            {children}
        </CardActionArea>
    ) : (
        <>{children}</>
    );
};

interface TasksGroupProps {
    tasks: ReturnType<typeof useTasks>;
    content: TaskDTO[];
    addIndividualAssignees: (args1: {
        task: TaskDTO;
    }) => (args2: { newAssignees: PersonReferenceDTO[]; comment: string | null }) => void;
    isLoading: boolean;
    disabled: boolean;
    withUnsavedChangesPrompt: (f: () => void) => void;
    onTaskChange: () => void;
    unselectable: boolean;
}

const TasksGroup: React.FC<TasksGroupProps> = ({
    tasks,
    content,
    addIndividualAssignees,
    isLoading,
    disabled,
    withUnsavedChangesPrompt,
    onTaskChange,
    unselectable,
}) => {
    const { currentTask, setCurrentTask } = tasks;
    const isAdmin = useAdminRole();
    const intl = useIntl();
    const canBeCompleted = useTaskCanBeCompleted();
    const { isMobile } = useDeviceType();
    const { closeDrawer, triggerActionButtonAnimation } = useContext(DocumentDrawerContext)!;
    const addDocumentType = (t: TaskDTO) => {
        // TODO
        // FIXME what is this???
        // when we decide that to show in the task card, for is37 change requests - and probably activity change
        // requests - we have to add information that this signature applies to the change request no the document
        if (t.documentType == DocumentType.IS37_CR) {
            return 'IS37 Change Request: ';
        } else return '';
    };
    const isCurrentTask = useCallback((task: Pick<TaskDTO, 'id'>) => currentTask?.id === task.id, [currentTask]);
    const isTaskImportant = useTaskIsImportant();
    const handleCardClick = useCallback(
        (task: TaskDTO) =>
            withUnsavedChangesPrompt(() => {
                onTaskChange();
                setCurrentTask(currentTask?.id !== task.id || !unselectable ? task : null);
                triggerActionButtonAnimation();
                if (isMobile) {
                    closeDrawer();
                }
            }),
        [currentTask, setCurrentTask, isCurrentTask, withUnsavedChangesPrompt]
    );
    const getTaskColor = useCallback(
        (task: TaskDTO) => {
            if (isCurrentTask(task)) {
                // Selected task
                return blue[50];
            } else if (canBeCompleted(task)) {
                // Selectable task (but not currently selected)
                return 'white';
            } else if (task.taskActive) {
                // Unselectable active task
                return grey[100];
            } else {
                // Inactive task
                if (task.taskCompletionCode !== null) {
                    // Performed
                    const actionType = task.taskCompletionCode;
                    const taskConfiguration = TASK_CONFIGURATION[task.type.type];
                    const actionConfiguration = taskConfiguration.all
                        ? taskConfiguration.actions[actionType]
                        : taskConfiguration.actions[task.documentType]?.[actionType];
                    const configuration = Array.isArray(actionConfiguration) ? undefined : actionConfiguration; // TODO
                    const colors = {
                        [ActionKind.MUST_DO]: green[50],
                        [ActionKind.CAN_DO_SOFT]: orange[50],
                        [ActionKind.CAN_DO_HARD]: red[50],
                    } satisfies Record<ActionKind, string>;
                    return colors[configuration?.kind ?? DEFAULT_ACTION_KIND];
                } else {
                    // Not performed
                    return grey[300];
                }
            }
        },
        [isCurrentTask, canBeCompleted]
    );
    return (
        <Stack direction="column" spacing={2}>
            {content.map((task) => (
                <Card
                    key={task.id}
                    variant={isCurrentTask(task) ? undefined : 'outlined'}
                    sx={{
                        background: getTaskColor(task),
                        borderStyle: !isCurrentTask(task) && !isTaskImportant(task) ? 'dashed' : undefined,
                    }}
                >
                    <CustomCardActionArea
                        onClick={() => handleCardClick(task)}
                        readOnly={!canBeCompleted(task)}
                        disabled={disabled}
                    >
                        <CardHeader
                            avatar={
                                <Box color={isCurrentTask(task) ? blue[500] : 'rgba(0, 0, 0, 0.54)'}>
                                    {task ? TASK_CONFIGURATION[task.type.type].icon ?? <Task /> : null}
                                </Box>
                            }
                            action={
                                <Stack
                                    onClick={(e) => e.stopPropagation()}
                                    onTouchStart={(e) => e.stopPropagation()}
                                    onMouseDown={(e) => e.stopPropagation()}
                                    direction="column"
                                    justifyContent="space-between"
                                    spacing={0.5}
                                >
                                    {/* This `<Stack/>` prevent the propagation of unrelated events to `<CardActionArea/>` */}
                                    <TaskManageButton
                                        task={task}
                                        mutateAddAssignees={addIndividualAssignees({ task })}
                                        disabled={isLoading}
                                        moreActionsDisabled={tasks.disabled || disabled}
                                        readOnly={!canBeCompleted(task)}
                                    />
                                </Stack>
                            }
                            title={
                                <Box sx={{ fontWeight: 'medium' }}>
                                    {addDocumentType(task) + translateTaskType(task.type, intl.locale).typeName}
                                </Box>
                            }
                            subheader={
                                <>
                                    <Typography variant="body2" fontSize={12} fontWeight="medium">
                                        {translateTask(task, intl.locale).assignmentLabel}
                                    </Typography>
                                    <Typography variant="body2" fontSize={10}>
                                        {task.taskActive
                                            ? task.assignees.length === 1
                                                ? task.assignees[0].searchLabel
                                                : intl.formatMessage(
                                                      { id: 'document.task.assignees.count' },
                                                      {
                                                          person: task.assignees.length,
                                                      }
                                                  )
                                            : task.taskExecutor !== null
                                            ? intl.formatMessage(
                                                  { id: 'document.task.performedBy' },
                                                  {
                                                      person: task.taskExecutor.searchLabel,
                                                      date: formatDateTime(task.closedDate!, intl),
                                                  }
                                              )
                                            : intl.formatMessage({ id: 'document.task.notPerformed' })}
                                        {}
                                    </Typography>
                                    {task.taskActive && canBeCompleted(task) && (
                                        <Typography fontSize={14} sx={{ mt: 1 }}>
                                            {TASK_CONFIGURATION[task.type.type].instruction(intl)}
                                        </Typography>
                                    )}
                                </>
                            }
                        />
                        {/* TODO list of steps to complete the task inside the card content or a collapsible panel */}
                    </CustomCardActionArea>
                </Card>
            ))}
        </Stack>
    );
};

interface TaskHistoryProps {
    tasks: ReturnType<typeof useTasks>;
    disabled: boolean;
    withUnsavedChangesPrompt: (f: () => void) => void;
    onTaskChange: () => void;
    onTaskUpdate: () => void;
}

export const TaskHistory: React.FC<TaskHistoryProps> = ({
    tasks: tasksData,
    disabled,
    withUnsavedChangesPrompt,
    onTaskChange,
    onTaskUpdate,
}) => {
    const intl = useIntl();
    const { isLoading, isError, assignableTasks, otherTasks, completedTasks, cancelledTasks, currentTask } = tasksData;

    const { setActiveTasksCount, setTaskSelected } = useContext(DocumentDrawerContext)!;

    const { mutate: mutateAddAssignees, isLoading: isAddAssigneesLoading } = useMutation(
        (data: { taskId: string; newAssignees: PersonReferenceDTO[]; comment: string | null }) =>
            addTaskAssignees({
                taskId: data.taskId,
                newAssignees: data.newAssignees,
                comment: { comment: data.comment },
            }).then(artificialDelay),
        {
            onSuccess: onTaskUpdate,
            onError: (error: any) => {
                if (isApiError(error) && error?.status === HttpCode.CONFLICT) {
                    const validationError = translateApiError(error.data, intl.locale);
                    toast.warning(validationError.message);
                } else {
                    toast.warning(intl.formatMessage({ id: 'document.error.conflict' }));
                }
            },
        }
    );
    const addIndividualAssignees =
        ({ task }: { task: TaskDTO }) =>
        ({ newAssignees, comment }: { newAssignees: PersonReferenceDTO[]; comment: string | null }) =>
            mutateAddAssignees({
                taskId: task.id,
                newAssignees,
                comment,
            });

    useEffect(() => {
        // Behaves weirdly if there is more than one page (shouldn't happen)
        setActiveTasksCount(assignableTasks.length);
    }, [setActiveTasksCount, assignableTasks]);
    useEffect(() => {
        setTaskSelected(currentTask);
    }, [setTaskSelected, currentTask]);

    const [selectedGroup, setSelectedGroup] = useState(0);

    const categories = [
        {
            label: intl.formatMessage({ id: 'document.tasks.myActive' }),
            content: assignableTasks,
        },
        { label: intl.formatMessage({ id: 'document.tasks.otherActive' }), content: otherTasks },
        { label: intl.formatMessage({ id: 'document.tasks.completed' }), content: completedTasks },
        { label: intl.formatMessage({ id: 'document.tasks.closed' }), content: cancelledTasks },
    ].map((o) => ({ ...o, isCurrent: o.content.some(({ id }) => id === currentTask?.id) }));

    const isTaskImportant = useTaskIsImportant();
    const existsImportantTask = assignableTasks.some(isTaskImportant);

    return (
        <>
            {isLoading ? (
                <LinearProgress />
            ) : isError ? (
                <Box sx={{ px: 2, pt: 2 }}>
                    <Alert severity="error">
                        <FormattedMessage id="document.tasks.error" />
                    </Alert>
                </Box>
            ) : categories.every((task) => task.content.length === 0) ? (
                <Box sx={{ px: 2, pt: 2 }}>
                    <Alert severity="info">
                        <FormattedMessage id="document.tasks.empty" />
                    </Alert>
                </Box>
            ) : (
                <Box>
                    {categories
                        .filter(({ content }) => content.length > 0)
                        .map(({ label, content, isCurrent }, i) => (
                            <Accordion key={i} expanded={selectedGroup === i} onChange={() => setSelectedGroup(i)}>
                                <AccordionSummary>
                                    <Stack direction="row" spacing={1}>
                                        <Typography>{label}</Typography>
                                        <Chip
                                            label={content.length}
                                            color={isCurrent ? 'info' : undefined}
                                            size="small"
                                            sx={{ cursor: 'inherit' }}
                                        />
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TasksGroup
                                        tasks={tasksData}
                                        content={content}
                                        addIndividualAssignees={addIndividualAssignees}
                                        isLoading={isAddAssigneesLoading}
                                        disabled={disabled}
                                        withUnsavedChangesPrompt={withUnsavedChangesPrompt}
                                        onTaskChange={onTaskChange}
                                        unselectable={!existsImportantTask}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        ))}
                </Box>
            )}
        </>
    );
};
