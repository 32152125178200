import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const sizing = { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 };

const uiRadiationSchema: TFormElement = {
    type: UiSchemaType.PANEL,
    label: 'Report parameters',
    element: {
        type: UiSchemaType.LAYOUT_HORIZONTAL,
        elements: [
            {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.AUTOCOMPLETE,
                            label: 'Report',
                            path: 'report',
                            typeProperties: {
                                options: [
                                    {
                                        label: 'Summary',
                                        value: 'summary',
                                    },
                                    {
                                        label: 'Radiation dose by activity',
                                        value: 'byActivity',
                                    },
                                    {
                                        label: 'Daily radiation dose by activity',
                                        value: 'dailyByActivity',
                                    },
                                    {
                                        label: 'Radiation dose by person',
                                        value: 'byPerson',
                                    },
                                    {
                                        label: 'Historical radiation dose',
                                        value: 'historical',
                                    },
                                ],
                                getOptionLabel: (option) => option,
                            },
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Responsible Unit',
                            path: 'responsibleUnit',
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Contractor',
                            path: 'contractor',
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Cluster Rad. Resp.',
                            path: 'clusterRadiationResponsible',
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Participant',
                            path: 'participant',
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Participant Unit',
                            path: 'participantUnit',
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Activity',
                            path: 'activity',
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Activity responsible',
                            path: 'activityResponsible',
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Cluster',
                            path: 'cluster',
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Cluster Responsible',
                            path: 'clusterResponsible',
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'DIMR',
                            path: 'dimr',
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.AUTOCOMPLETE,
                            label: 'Facility',
                            path: 'facility',
                            typeProperties: {
                                options: [],
                                getOptionLabel: (option) => option,
                            },
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.DATE,
                            label: 'From',
                            path: 'from',
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.DATE,
                            label: 'To',
                            path: 'to',
                        },
                    },
                ],
            },
            {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.RANGE,
                            label: 'Collective dose (%)',
                            typeProperties: {
                                fromPath: 'collectiveDoseFrom',
                                toPath: 'collectiveDoseTo',
                            },
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.RANGE,
                            label: 'Individual dose (%)',
                            typeProperties: {
                                fromPath: 'individualDoseFrom',
                                toPath: 'individualDoseTo',
                            },
                        },
                    },
                    {
                        ...sizing,
                        element: {
                            type: UiSchemaType.RANGE,
                            label: 'Last 12 months (µSv)',
                            typeProperties: {
                                fromPath: 'lastYearFrom',
                                toPath: 'lastYearTo',
                            },
                        },
                    },
                ],
            },
        ],
    },
};

const radiationSchema = z.object({
    report: z.string(),
    participant: z.string(),
    participantUnit: z.string(),
    activity: z.string(),
    activityResponsible: z.string(),
    cluster: z.string(),
    clusterResponsible: z.string(),
    dimr: z.string(),
    facility: z.string(),
    from: z.date(),
    to: z.date(),
    responsibleUnit: z.string(),
    contractor: z.string(),
    clusterRadiationResponsible: z.string(),
    collectiveDoseFrom: z.number(),
    collectiveDoseTo: z.number(),
    individualDoseFrom: z.number(),
    individualDoseTo: z.number(),
    lastYearFrom: z.number(),
    lastYearTo: z.number(),
});

interface RadiationDoseFormProps {}
export const RadiationDoseForm: React.FC<RadiationDoseFormProps> = () => {
    const form = useForm({
        mode: 'onSubmit',
        resolver: zodResolver(radiationSchema),
    });

    return <Box sx={{ margin: 2 }}>{/*<FormGenerator form={form} rootElement={formRootElement} />*/}</Box>;
};
