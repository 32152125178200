import { sfAnd, sfEqual, sfOr } from 'spring-filter-query-builder';
import { PersonRichReadDTO } from '../../api/dto';
import { DocumentDetails } from '../../components/tasks/useTasks';
import { escapeSfqbString } from '../sfqb';

export const makeDocumentTaskFilter = ({
    documentDetails,
    assignee,
}: {
    documentDetails: DocumentDetails[];
    assignee?: PersonRichReadDTO;
}): string => {
    return sfAnd([
        sfOr(
            documentDetails.map((documentDetail) =>
                sfAnd([
                    sfEqual('documentType', escapeSfqbString(String(documentDetail.documentType), false)),
                    sfEqual('documentId', escapeSfqbString(String(documentDetail.documentId), false)),
                ])
            )
        ),
        ...(assignee ? [sfEqual('assignees.person.searchLabel', escapeSfqbString(assignee.searchLabel, false))] : []),
    ]).toString();
};
