import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { DocumentTypeContainerDTO, ListOfValuesReadDTO } from './dto';
import { GetManyUnpaged } from './types';

const RESOURCE_PATH = 'document';

export const getDocumentStatuses: GetManyUnpaged<{}, DocumentTypeContainerDTO<ListOfValuesReadDTO>> = async ({
    config,
} = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'statuses']), { ...config }).then((response: AxiosResponse) => response.data);
