import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { getDocumentStatuses } from '../../../api/document';
import {
    DocumentTypeContainerDTO,
    ListOfValuesReadDTO,
    PersonReadDTO,
    SavedFilterType,
    TaskDTO,
    TaskType,
    TaskTypeDTO,
} from '../../../api/dto';
import { getTasks, getTaskTypes } from '../../../api/task';
import { getFacilities } from '../../../api/values';
import { useApplicationSettings } from '../../../components/application/ApplicationSettingsProvider';
import { ImpactLink } from '../../../components/ImpactLink';
import { PersonTooltip } from '../../../components/PersonTooltip';
import { DocumentResultCard } from '../../../components/searching/DocumentResultCard';
import {
    BooleanFilterOption,
    FilterOperatorEnum,
    FilterTypeEnum,
    SearchFilter,
} from '../../../components/searching/filters';
import { SearchPage } from '../../../components/searching/SearchPage';
import { StatusChip } from '../../../components/StatusChip';
import { DATATYPES } from '../../../components/tables/table/utils';
import { FetchUseCase } from '../../../forms/fields/AsyncAutocompleteElement';
import { activityFilterSchema } from '../../../forms/schemas/activity';
import { documentTypeFilterSchema } from '../../../forms/schemas/documentType';
import { personFilterSchema } from '../../../forms/schemas/person';
import { valueFilterSchema } from '../../../forms/schemas/value';
import { TFilterElement } from '../../../forms/types';
import { DocumentColorMapping } from '../../../lib/colors/DocumentColorMapping';
import { EntityType } from '../../../lib/information/types/EntityType';
import { getLovLabel } from '../../../lib/label';
import { translateTaskType, translateValue } from '../../../lib/language';
import { getDocumentName, getDocumentTypeName, getDocumentUrl } from '../../../lib/records/documents/document';
import { getTaskStatus } from '../../../lib/status';
import { getRoute, ROUTES } from '../../../routes/routes';

const ASSIGNEE_ID = 'assignees.searchLabel';

const taskTypeSchema = (intl: IntlShape): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id: 'type',
    label: intl.formatMessage({ id: 'task.field.type' }),
    valueGetter: (value: TaskTypeDTO) => value.type,
    typeProperties: {
        idField: 'type',
        fetchOptions: () => getTaskTypes(),
        fetchUseCases: [FetchUseCase.ON_LOAD],
        getOptionLabel: (value: TaskTypeDTO) => translateTaskType(value, intl.locale).typeName,
        autocompleteProps: {
            openOnFocus: true,
            disableCloseOnSelect: false,
        },
    },
});

const filterFormSchema = (intl: IntlShape): TFilterElement[] => [
    documentTypeFilterSchema({ id: 'documentType', onlyWithWorkflow: true }, intl),
    taskTypeSchema(intl),
    personFilterSchema({ id: ASSIGNEE_ID, label: intl.formatMessage({ id: 'task.field.assignee' }) }),
    {
        id: 'createdDate',
        label: intl.formatMessage({ id: 'task.field.createdDate' }),
        type: FilterTypeEnum.DATE,
    },
    {
        id: 'taskActive',
        label: intl.formatMessage({ id: 'task.field.active' }),
        type: FilterTypeEnum.BOOLEAN,
    },
    personFilterSchema({
        id: 'taskExecutor.searchLabel',
        label: intl.formatMessage({ id: 'task.field.executor' }),
    }),
    {
        id: 'closedDate',
        label: intl.formatMessage({ id: 'task.field.closedDate' }),
        type: FilterTypeEnum.DATE,
    },
    {
        id: 'document.title',
        label: intl.formatMessage({ id: 'task.field.documentTitle' }),
        type: FilterTypeEnum.STRING,
    },
    {
        id: 'document.createdDate',
        label: intl.formatMessage({ id: 'task.field.documentCreatedDate' }),
        type: FilterTypeEnum.DATE,
    },
    personFilterSchema({
        id: 'document.createdBy.searchLabel',
        label: intl.formatMessage({ id: 'task.field.documentCreatedBy' }),
    }),
    {
        type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
        id: 'document.status.code',
        label: intl.formatMessage({ id: 'task.field.documentStatus' }),
        valueGetter: (value: DocumentTypeContainerDTO<ListOfValuesReadDTO>) => value.data.code,
        typeProperties: {
            idField: 'data.code',
            fetchOptions: () => getDocumentStatuses(),
            fetchUseCases: [FetchUseCase.ON_LOAD],
            getOptionLabel: (value: DocumentTypeContainerDTO<ListOfValuesReadDTO>) => {
                const valueLabel = getLovLabel(translateValue(value.data, intl.locale));
                return intl.formatMessage(
                    { id: 'common.labelValue' },
                    { label: getDocumentTypeName(value.type, intl), value: valueLabel }
                );
            },
            autocompleteProps: {
                openOnFocus: true,
                disableCloseOnSelect: false,
                isOptionEqualToValue: (
                    o: DocumentTypeContainerDTO<ListOfValuesReadDTO> | undefined,
                    v: DocumentTypeContainerDTO<ListOfValuesReadDTO> | undefined
                ) => (o && v ? o.data?.code === v.data?.code : o === v),
            },
        },
    },
    valueFilterSchema(
        {
            id: 'document.facility.code',
            label: intl.formatMessage({ id: 'task.field.documentFacility' }),
            fetchOptions: getFacilities,
        },
        intl.locale
    ),
    {
        id: 'startDate',
        label: intl.formatMessage({ id: 'task.field.documentStartDate' }),
        type: FilterTypeEnum.DATE,
    },
    {
        id: 'endDate',
        label: intl.formatMessage({ id: 'task.field.documentEndDate' }),
        type: FilterTypeEnum.DATE,
    },
    activityFilterSchema(
        {
            id: 'document.activityId',
            label: intl.formatMessage({ id: 'task.field.documentActivity' }),
        },
        intl
    ),
];

export const getFilterSchemaForTasks = (intl: IntlShape) => filterFormSchema(intl);

const columnHelper = createColumnHelper<TaskDTO>();

const columns = (intl: IntlShape) => [
    columnHelper.accessor((row) => getDocumentName(row, intl), {
        id: 'documentId',
        header: intl.formatMessage({ id: 'document.name' }),
        size: 150,
        cell: ({ row: { original } }) => (
            <ImpactLink to={getDocumentUrl(original)}>{getDocumentName(original, intl)}</ImpactLink>
        ),
    }),
    columnHelper.accessor((row) => row.document?.title ?? '', {
        id: 'document.title',
        header: intl.formatMessage({ id: 'task.field.documentTitle' }),
        size: 240,
    }),
    columnHelper.accessor((row) => translateTaskType(row.type, intl.locale).typeName, {
        id: 'type',
        header: intl.formatMessage({ id: 'task.field.type' }),
        size: 150,
    }),
    columnHelper.accessor(
        (row) => (row.document?.status ? translateValue(row.document.status, intl.locale).label : ''),
        {
            id: 'document.status.label',
            header: intl.formatMessage({ id: 'task.field.documentStatus' }),
            size: 140,
            meta: {
                dataType: DATATYPES.ENUMERATION,
            },
            cell: ({ getValue, row }) =>
                DocumentColorMapping[row.original.documentType] !== undefined && row.original.document?.status ? (
                    <StatusChip
                        label={getValue()}
                        color={DocumentColorMapping[row.original.documentType]![row.original.document.status.code]}
                        size="small"
                    />
                ) : null,
        }
    ),
    columnHelper.accessor((row) => String(row.assignees.length), {
        id: 'assignees',
        header: intl.formatMessage({ id: 'task.field.assignees' }),
        size: 240,
        cell: ({ row: { original } }) =>
            original.assignees.length === 1 ? (
                <PersonTooltip person={original.assignees[0]}>
                    <Typography variant="inherit">{original.assignees[0].searchLabel}</Typography>
                </PersonTooltip>
            ) : (
                <FormattedMessage id="document.task.assignees.count" values={{ person: original.assignees.length }} />
            ),
        enableSorting: false,
    }),
    columnHelper.accessor((row) => row.createdDate, {
        id: 'createdDate',
        header: intl.formatMessage({ id: 'task.field.createdDate' }),
        size: 120,
        meta: {
            dataType: DATATYPES.DATETIME,
        },
    }),
    columnHelper.accessor((row) => row.closedDate, {
        id: 'closedDate',
        header: intl.formatMessage({ id: 'task.field.closedDate' }),
        size: 120,
        meta: {
            dataType: DATATYPES.DATETIME,
        },
    }),
    columnHelper.accessor((row) => row.taskExecutor?.searchLabel ?? '', {
        id: 'taskExecutor.searchLabel',
        header: intl.formatMessage({ id: 'task.field.executor' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.taskExecutor && (
                <PersonTooltip person={original.taskExecutor}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
    }),
    columnHelper.accessor((row) => getTaskStatus(row, intl).label, {
        id: 'status.label',
        header: intl.formatMessage({ id: 'task.field.status' }),
        size: 140,
        meta: {
            dataType: DATATYPES.ENUMERATION,
            initiallyHidden: true,
        },
        enableSorting: false,
        cell: ({ getValue, row }) => (
            <StatusChip label={getValue()} color={getTaskStatus(row.original, intl).color} size="small" />
        ),
    }),
    columnHelper.accessor((row) => row.document?.createdDate ?? '', {
        id: 'document.createdDate',
        header: intl.formatMessage({ id: 'task.field.documentCreatedDate' }),
        size: 120,
        meta: {
            dataType: DATATYPES.DATETIME,
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.document?.createdBy?.searchLabel ?? '', {
        id: 'document.createdBy.searchLabel',
        header: intl.formatMessage({ id: 'task.field.documentCreatedBy' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.document?.createdBy && (
                <PersonTooltip person={original.document.createdBy}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor(
        (row) => (row.document?.type?.label ? getLovLabel(translateValue(row.document.type, intl.locale)) : ''),
        {
            id: 'document.type.label',
            header: intl.formatMessage({ id: 'task.field.documentMainType' }),
            size: 120,
            meta: {
                dataType: DATATYPES.ENUMERATION,
                initiallyHidden: true,
            },
        }
    ),
    columnHelper.accessor(
        (row) => (row.document?.facility?.label ? getLovLabel(translateValue(row.document.facility, intl.locale)) : ''),
        {
            id: 'document.facility.label',
            header: intl.formatMessage({ id: 'task.field.documentFacility' }),
            size: 120,
            meta: {
                dataType: DATATYPES.ENUMERATION,
                initiallyHidden: true,
            },
        }
    ),
    columnHelper.accessor((row) => row.document?.startDate ?? '', {
        id: 'document.startDate',
        header: intl.formatMessage({ id: 'task.field.documentStartDate' }),
        size: 120,
        meta: {
            dataType: DATATYPES.DATETIME,
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.document?.endDate ?? '', {
        id: 'document.endDate',
        header: intl.formatMessage({ id: 'task.field.documentEndDate' }),
        size: 120,
        meta: {
            dataType: DATATYPES.DATETIME,
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.document?.activityId ?? '', {
        id: 'document.activityId',
        header: intl.formatMessage({ id: 'task.field.documentActivity' }),
        size: 80,
        cell: ({ getValue }) => (
            <ImpactLink
                to={getRoute({
                    path: ROUTES.activity.view,
                    params: { id: String(getValue()) },
                })}
            >
                {getValue()}
            </ImpactLink>
        ),
        meta: {
            initiallyHidden: true,
        },
    }),
];

const getAssignedTasksFilter = (initialPerson: PersonReadDTO | undefined): SearchFilter[] => [
    {
        field: ASSIGNEE_ID,
        operator: FilterOperatorEnum.IS,
        value: initialPerson,
    },
];

const getActiveTasksFilter = (intl: IntlShape): SearchFilter[] => [
    {
        field: 'taskActive',
        operator: FilterOperatorEnum.IS,
        value: {
            id: BooleanFilterOption.TRUE,
            label: intl.formatMessage({ id: 'search.filters.value.true' }),
        },
    },
];

const getExecutedTasksFilter = (initialPerson: PersonReadDTO | undefined): SearchFilter[] => [
    {
        field: 'taskExecutor.searchLabel',
        operator: FilterOperatorEnum.IS,
        value: initialPerson,
    },
];

export const getMyActiveTasksFilter = (initialPerson: PersonReadDTO | undefined, intl: IntlShape): SearchFilter[] => [
    ...getActiveTasksFilter(intl),
    ...getAssignedTasksFilter(initialPerson),
];

const PENDING_TASKS: Set<TaskType> = new Set([
    TaskType.SIGNATURE,
    TaskType.SUBMIT,
    TaskType.ADD_IS37_SENSORS,
    TaskType.DISABLE_SENSORS,
    TaskType.RECOMMISSION_SENSORS,
    TaskType.SCHEDULE_RECOMMISSIONING,
    TaskType.FP_CLOSEOUT,
    TaskType.COMPLETE_WORK,
    TaskType.PROVIDE_YELLOW_PAPER,
    TaskType.RETURN_YELLOW_PAPER,
    TaskType.UNLOCK_LOCKOUT,
    TaskType.CONFIRM_RECEPTION_YELLOW_PAPER,
    TaskType.CONFIRM_RETURN_YELLOW_PAPER,
    TaskType.DIMR_FEEDBACK,
    TaskType.DIMR_CLOSURE,
]);

export const getMyPendingTasksFilter = (
    initialPerson: PersonReadDTO | undefined,
    taskTypes: TaskTypeDTO[],
    intl: IntlShape
): SearchFilter[] => [
    ...getMyActiveTasksFilter(initialPerson, intl),
    {
        field: 'type',
        operator: FilterOperatorEnum.IS_ANY_OF,
        value: taskTypes.filter((t) => PENDING_TASKS.has(t.type)),
    },
];

interface TaskSearchPageProps {
    header?: React.ReactNode;
}

export const TaskSearchPage: React.FC<TaskSearchPageProps> = ({ header }) => {
    const intl = useIntl();
    const { currentUser, taskTypes } = useApplicationSettings();
    return (
        <SearchPage
            title={intl.formatMessage({ id: 'title.taskSearch' })}
            filterDialogTitle={intl.formatMessage({ id: 'title.taskSearch' })}
            columns={columns(intl)}
            pageKey="TaskSearchPage"
            documentType={null}
            filterSchemas={filterFormSchema(intl)}
            initialFilters={getMyPendingTasksFilter(currentUser, taskTypes, intl)}
            pagedApiCall={getTasks}
            CardItem={({ item, handleExpandToggle, isExpanded }) => (
                <DocumentResultCard
                    key={item.id}
                    document={{ type: EntityType.Task, task: item }}
                    isExpanded={isExpanded(item)}
                    onExpandToggle={() => handleExpandToggle(item)}
                />
            )}
            initialSort={{ id: 'createdDate', desc: true }}
            selectable={false}
            header={header}
            predefinedFilters={[
                {
                    filters: getMyPendingTasksFilter(currentUser, taskTypes, intl),
                    labelKey: 'search.filters.myPendingTasks',
                },
                {
                    filters: getMyActiveTasksFilter(currentUser, intl),
                    labelKey: 'search.filters.myActiveTasks',
                },
                {
                    filters: getExecutedTasksFilter(currentUser),
                    labelKey: 'search.filters.myCompletedTasks',
                },
                { filters: getActiveTasksFilter(intl), labelKey: 'search.filters.allActiveTasks' },
            ]}
            savedFilterType={SavedFilterType.TASKS}
        />
    );
};
