/* eslint-disable @typescript-eslint/no-use-before-define */
import { AddCircleOutline, Link, LinkOff } from '@mui/icons-material';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    Stack,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { createDimr, dimrAttachmentsApi, getDimrContext, updateDimr } from '../../api/documents/dimrs';
import {
    ActivityReadDTO,
    CommentDTO,
    DimrVersionContextReadDTO,
    DimrVersionReadDTO,
    DocumentActionDTO,
    DocumentType,
    ListOfValuesReadDTO,
    RpAssessmentReadDTO,
    TaskCode,
} from '../../api/dto';
import { useApplicationSettings } from '../../components/application/ApplicationSettingsProvider';
import { CommentList } from '../../components/comments/CommentList';
import { useConfirmationDialog } from '../../components/ConfirmationDialogContext';
import { DashedButton } from '../../components/DashedButton';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../components/documents/DocumentButtonsStack';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { DoseReportButton } from '../../components/documents/DoseReportButton';
import { LinkedDocumentsPanel } from '../../components/documents/LinkedDocumentsPanel';
import { useTaskContext } from '../../components/TaskContext';
import { TaskActions } from '../../components/tasks/TaskActions';
import { TaskHistory } from '../../components/tasks/TaskHistory';
import { useTasks } from '../../components/tasks/useTasks';
import { CustomErrorResolver, ValidationErrorsPanel } from '../../components/ValidationErrorsPanel';
import { WorkflowHistory } from '../../components/WorkflowHistory';
import { useDocumentCreationToast } from '../../hooks/useDocumentCreationToast';
import { useUnsavedChangesPrompt } from '../../hooks/useUnsavedChangesPrompt';
import { useDocumentSaveErrorCallback } from '../../hooks/validation';
import { fetchActiveOrDraftActivitiesById } from '../../lib/api';
import { artificialDelay } from '../../lib/delay';
import { EntityType } from '../../lib/information/types/EntityType';
import {
    makeDefaultRpAssessmentValuesFromLinkedActivity,
    makeDimrDefaultValuesFromLinkedActivity,
    mapDimrToUpdateDTO,
} from '../../lib/records/documents/dimr';
import { DIMRContentHeader } from '../../pages/documents/dimr/DIMRContentHeader';
import { DIMRPageData } from '../../pages/documents/dimr/DIMRPageData';
import { DIMRPageVariant } from '../../pages/documents/dimr/DIMRPageVariant';
import { AttachmentsAction } from '../../pages/documents/ndc/AttachmentsAction';
import { getRoute, ROUTES } from '../../routes/routes';
import { FieldsAttributesContextProvider } from '../FieldsAttributesContext';
import { FormGenerator } from '../FormGenerator';
import { FieldAttributesBase, PreciseRulesDTO } from '../rule/deserializer';
import { useFormRules } from '../rule/useFormRules';
import { useIsSavableFromRules } from '../rule/useIsSavableFromRules';
import { activitySchema } from '../schemas/activity';
import { dimrFormSchema } from '../schemas/documents/dimr';
import { joinFieldPath } from '../utils';
import { DynamicValidationFormWrapper } from './DynamicValidationFormWrapper';

const RP_ASSESSMENTS_FIELD = 'rpAssessments' as const satisfies keyof DimrVersionReadDTO;

interface DimrLinkActivityDialogProps {
    open: boolean;
    onClose: () => void;
}

const DimrLinkActivityDialog: React.FC<DimrLinkActivityDialogProps> = ({ open, onClose }) => {
    const intl = useIntl();
    const form = useForm<{ activity: ActivityReadDTO | undefined }>();
    const formSchema = useMemo(
        () =>
            activitySchema(
                {
                    path: 'activity',
                    label: intl.formatMessage({ id: 'activity.name' }),
                    fetchActivities: fetchActiveOrDraftActivitiesById,
                },
                intl
            ),
        [intl]
    );
    const { watch, setValue } = useFormContext<DimrVersionReadDTO>();
    const rpAssessments = watch(RP_ASSESSMENTS_FIELD);
    const selectedActivity = form.watch('activity');
    const canSubmit = !!selectedActivity && rpAssessments.every(({ activity: { id } }) => id !== selectedActivity.id);
    const handleSubmit = form.handleSubmit(({ activity }) => {
        if (activity) {
            setValue(
                RP_ASSESSMENTS_FIELD,
                [...rpAssessments, makeDefaultRpAssessmentValuesFromLinkedActivity(activity)] as any,
                {
                    shouldDirty: true,
                }
            );
            onClose();
        }
    });
    useEffect(() => {
        if (open) {
            form.reset();
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>
                <FormattedMessage id="dimr.dialog.linkActivity" />
            </DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 1 }}>
                    <FormProvider {...form}>
                        <FieldsAttributesContextProvider value={undefined}>
                            <FormGenerator form={form} rootElement={formSchema} />
                        </FieldsAttributesContextProvider>
                    </FormProvider>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ color: grey[600] }}>
                    <FormattedMessage id="common.close" />
                </Button>
                <Button disabled={!canSubmit} onClick={handleSubmit}>
                    <FormattedMessage id="dimr.dialog.add" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

interface DimrLinkedActivitiesDialogProps {
    attributes: Record<string, FieldAttributesBase>;
    isLoading: boolean;
    open: boolean;
    onClose: () => void;
    readOnly: boolean;
}

const DimrLinkedActivitiesDialog: React.FC<DimrLinkedActivitiesDialogProps> = ({
    attributes,
    isLoading,
    open,
    onClose,
    readOnly,
}) => {
    const intl = useIntl();
    const confirmation = useConfirmationDialog();
    const [isLinkActivityDialogOpen, setLinkActivityDialogOpen] = useState(false);
    const { watch, setValue } = useFormContext<DimrVersionReadDTO>();
    type RpAssessmentIdentifier = { activity: Pick<ActivityReadDTO, 'id'> };
    const rpAssessments: RpAssessmentIdentifier[] = watch(RP_ASSESSMENTS_FIELD);
    const handleUnlink = (rpa: RpAssessmentIdentifier) => {
        confirmation({
            prompt: intl.formatMessage({ id: 'dimr.dialog.confirm' }),
            onConfirm: () =>
                setValue(
                    RP_ASSESSMENTS_FIELD,
                    rpAssessments.filter(({ activity: { id } }) => id !== rpa.activity.id) as RpAssessmentReadDTO[],
                    {
                        shouldDirty: true,
                    }
                ),
        });
    };
    const canUnlink = (rpa: RpAssessmentIdentifier) => {
        const index = rpAssessments.findIndex(({ activity: { id } }) => id === rpa.activity.id); // TODO determine how to implement this logic
        return attributes[RP_ASSESSMENTS_FIELD]?.editable !== false;
    };
    const canUnlinkAny = rpAssessments.length > 1 && !readOnly;
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                <FormattedMessage id="dimr.action.manageLinkedActivities" />
            </DialogTitle>
            <DialogContent>
                <List>
                    {rpAssessments.map((rpa) => (
                        <ListItem
                            key={rpa.activity.id}
                            secondaryAction={
                                canUnlinkAny &&
                                canUnlink(rpa) && (
                                    <IconButton disabled={isLoading} onClick={() => handleUnlink(rpa)}>
                                        <LinkOff />
                                    </IconButton>
                                )
                            }
                        >
                            <FormattedMessage id="activity.titleExisting" values={{ id: rpa.activity.id }} />
                        </ListItem>
                    ))}
                </List>
                {!readOnly && (
                    <DashedButton
                        disabled={isLoading}
                        onClick={() => setLinkActivityDialogOpen(true)}
                        startIcon={<AddCircleOutline />}
                    >
                        <FormattedMessage id="dimr.dialog.add" />
                    </DashedButton>
                )}
                <DimrLinkActivityDialog
                    open={isLinkActivityDialogOpen}
                    onClose={() => setLinkActivityDialogOpen(false)}
                    // saveDimr={saveDimr}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ color: grey[600] }}>
                    <FormattedMessage id="common.close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

interface DimrFormProps {
    data: DIMRPageData;
    rules: PreciseRulesDTO<DimrVersionContextReadDTO>;
    refreshData: () => void;
}

export const DimrForm: React.FC<DimrFormProps> = ({ data: pageData, rules, refreshData }) => {
    const intl = useIntl();
    const { variant } = pageData;
    const { currentUser } = useApplicationSettings();
    const navigate = useNavigate();
    const { withDispatchTaskEvent } = useTaskContext();

    const tasks = useTasks(
        pageData.variant === DIMRPageVariant.READ
            ? {
                  documentDetails: [{ documentType: DocumentType.DIMR, documentId: pageData.dimrData.id }],
                  onTaskSubmit: (taskHandler) => {
                      clearErrors();
                      return handleSubmit((data) => taskHandler(data))();
                  },
                  onTaskError: (response: AxiosResponse) => {
                      refetchContext();
                      handleFormSaveError(response, { validate: true, submit: false });
                  },
                  refreshData,
              }
            : undefined
    );

    const formRef = useRef<UseFormReturn<DimrVersionReadDTO>>();

    const isContextFetchingEnabled = tasks.currentTask !== null;
    const {
        data: contextData,
        refetch: refetchContext,
        isFetching: isContextFetching,
    } = useQuery(
        [
            'dimr',
            'context',
            pageData.variant === DIMRPageVariant.READ ? pageData.dimrData.id : 0,
            tasks.currentTask?.id,
        ],
        () => getDimrContext({ dimr: formRef.current!.getValues() as any, taskId: tasks.currentTask?.id ?? '' }),
        {
            enabled: isContextFetchingEnabled,
        }
    );
    const isContextLoading = isContextFetching && isContextFetchingEnabled;

    const context: DimrVersionContextReadDTO | null = useMemo(
        () => ({
            ...rules.context!,
            ...contextData?.context,
            documentAction: variant === DIMRPageVariant.CREATE ? DocumentActionDTO.CREATE : null,
            taskType: tasks.currentTask?.type?.type ?? null,
            taskCode: tasks.currentTask?.code ?? null,
        }),
        [rules.context, variant, contextData?.context, tasks.currentTask]
    );

    const defaultValues = useMemo(
        () =>
            pageData.variant === DIMRPageVariant.READ
                ? pageData.dimrData
                : makeDimrDefaultValuesFromLinkedActivity(pageData.activityData, pageData.suggestedValues),
        [pageData, currentUser]
    );
    const { form, attributes } = useFormRules<DimrVersionReadDTO>({
        formProps: {
            mode: 'onSubmit',
            defaultValues: {
                ...defaultValues,
            },
        },
        rules,
        originalValues: defaultValues,
        context: context as any,
        options: { ignoredFields: ['rpAssessments'] },
    });

    useLayoutEffect(() => {
        formRef.current = form;
    }, [form, formRef]);

    const { handleSubmit, clearErrors, formState, watch, setFocus } = form;
    const handleFormSaveError = useDocumentSaveErrorCallback(form, DocumentType.DIMR);

    const sendDocumentCreationToast = useDocumentCreationToast('dimr.name');

    const { mutateAsync: saveDimr, isLoading: isSaveLoading } = useMutation(
        ({ data: formData }: { data: any }) => {
            if (variant === DIMRPageVariant.READ) {
                if (tasks.currentTask === null) {
                    throw new Error();
                }
                return updateDimr({
                    id: pageData.dimrData.id,
                    taskId: tasks.currentTask.id,
                    data: mapDimrToUpdateDTO(formData, false),
                });
            } else {
                return createDimr({ data: mapDimrToUpdateDTO(formData, true) }).then(artificialDelay);
            }
        },
        {
            onSuccess: (result) => {
                if (variant === DIMRPageVariant.READ) {
                    refreshData();
                } else {
                    sendDocumentCreationToast();
                    navigate(
                        getRoute({
                            path: ROUTES.dimr.view,
                            params: { id: String(result.dimrMasterId), version: String(result.versionNumber) },
                        }),
                        {
                            replace: true,
                        }
                    );
                }
            },
            onError: (response: AxiosResponse) => {
                refetchContext();
                handleFormSaveError(response);
            },
        }
    );

    const [isActivityDialogOpen, setActivityDialogOpen] = useState(false);

    const isSavable = useIsSavableFromRules(attributes);
    const isCompletable = tasks.isLoading || !!tasks.assignableTasks.length;
    const { isDirty: formIsDirty } = formState;
    const isDirty = formIsDirty || variant !== DIMRPageVariant.READ;
    const isLoading = isSaveLoading || tasks.disabled || isContextLoading;
    const withUnsavedChangesPrompt = useUnsavedChangesPrompt({
        when: !isLoading && formIsDirty && isSavable,
    });
    const refreshDataWithUnsavedChangesPrompt = () => withUnsavedChangesPrompt(refreshData);

    const watchedRpAssessments = watch('rpAssessments');

    const facilities = useMemo(() => {
        const facilityByCode = Object.fromEntries(
            watchedRpAssessments
                .map(({ activity: { facility } }) => facility)
                .filter((facility): facility is ListOfValuesReadDTO => facility != null)
                .map((facility) => [facility.code, facility])
        );
        return Object.values(facilityByCode).sort((a, b) => (a.orderInType ?? 0) - (b.orderInType ?? 0));
    }, [watchedRpAssessments]);
    const workflowLocation = watch('locationWithHighestAreaClassification');

    const uiSchemaDimr = useMemo(
        () =>
            dimrFormSchema(
                {
                    dimrId: pageData.variant === DIMRPageVariant.READ ? pageData.dimrData.id : null,
                    currentTask: tasks.currentTask,
                    facilities,
                    workflowLocation,
                },
                intl
            ),
        [intl, tasks.currentTask?.id, facilities, workflowLocation]
    );
    const watchedDimrProperties = watch(['alaraLevel.code', 'calculatedEstColDose', 'calculatedMaxEstIndDose']);
    // I thought that this would make the context  refresh only when one of these fields is updates, but it just always
    // refreshes when an RP Assessment is edited
    const depsContextRefresh = watchedRpAssessments
        .flat()
        .flatMap((o) =>
            Object.values([
                o.rpPresence?.code,
                ...(o.locations?.map(({ id }) => id) ?? []),
                o.avgEstDoseRate,
                o.totalCollectiveWorkTime,
                o.maxIndividualWorkTime,
                o.maxEstDoseRate,
                o.maxEstAirborneCont,
                o.maxEstSurfaceCont,
            ])
        )
        .concat(watchedDimrProperties);

    useLayoutEffect(() => void refetchContext(), []);

    const hasOnlyOneRpAssessment =
        variant === DIMRPageVariant.READ
            ? pageData.dimrData.rpAssessments.length === 1
            : [{ activity: pageData.activityData }].length === 1;

    const hasNoRPOrRSOComment = (commentsData: CommentDTO[] | undefined) => {
        return !commentsData?.some(
            (comment) =>
                comment.task &&
                (comment.task.code === TaskCode.DIMR_RP_COMMENT || comment.task.code === TaskCode.DIMR_RSO_COMMENT)
        );
    };

    const customErrorResolver: CustomErrorResolver = useCallback((e) => {
        const property = 'rpAssessments' satisfies keyof DimrVersionReadDTO;
        if (!(e.path.length >= 2 && e.path[0] === property)) {
            return e;
        }
        const index = e.path[1];
        const id = joinFieldPath([property, index]);
        const clickHandler = () => {
            [document.getElementById(id)]
                .filter((el): el is HTMLElement => el !== null)
                .forEach((element) => element.click());
            setTimeout(() => {
                document.getElementsByName(joinFieldPath(e.path)).forEach((element) => {
                    element.focus();
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                });
            });
        };
        return {
            ...e,
            onClick: clickHandler,
        };
    }, []);

    return (
        <FormProvider {...form}>
            <DocumentPageStructure
                isLoading={isLoading}
                Header={
                    <DIMRContentHeader
                        data={pageData}
                        editable={isSavable || isCompletable}
                        isLoading={isLoading}
                        onRefreshClick={
                            variant === DIMRPageVariant.READ ? refreshDataWithUnsavedChangesPrompt : undefined
                        }
                        Main={null}
                        Left={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {tasks.currentTask !== null && (
                                    <TaskActions
                                        task={tasks.currentTask}
                                        onValidate={tasks.validate}
                                        onSubmit={tasks.perform}
                                        disabled={isLoading}
                                        getValues={form.getValues}
                                        context={context}
                                        isDirty={formIsDirty}
                                    />
                                )}
                                {isSavable && (
                                    <DocumentButtonsStack.Action
                                        {...(variant === DIMRPageVariant.CREATE
                                            ? COMMON_DOCUMENT_BUTTONS.CREATE
                                            : COMMON_DOCUMENT_BUTTONS.SAVE)}
                                        onClick={withDispatchTaskEvent(() => {
                                            clearErrors();
                                            return handleSubmit((data) => saveDimr({ data }))();
                                        })}
                                        disabled={!isDirty}
                                        disablingReason={intl.formatMessage({
                                            id: 'document.form.disabledNoChange',
                                        })}
                                        important={!tasks.currentTask}
                                        ignoreBlur={true}
                                    />
                                )}
                            </Stack>
                        }
                        Right={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {variant === DIMRPageVariant.READ && (
                                    <>
                                        <DoseReportButton dimrId={pageData.dimrData.dimrMasterId} />
                                        <DocumentButtonsStack.Action
                                            icon={<Link />}
                                            titleKey="dimr.action.manageLinkedActivities"
                                            onClick={() => setActivityDialogOpen(true)}
                                        />
                                        <AttachmentsAction
                                            attachments={pageData.dimrData.attachments}
                                            linkAttachments={(attachments) =>
                                                dimrAttachmentsApi.linkAttachments({
                                                    id: pageData.dimrData.id,
                                                    taskId: tasks.currentTask?.id!,
                                                    ...attachments,
                                                })
                                            }
                                            unlinkAttachments={(attachments) =>
                                                dimrAttachmentsApi.unlinkAttachments({
                                                    id: pageData.dimrData.id,
                                                    taskId: tasks.currentTask?.id!,
                                                    ...attachments,
                                                })
                                            }
                                            updateAttachment={(data) =>
                                                dimrAttachmentsApi.updateAttachment({
                                                    id: pageData.dimrData.id,
                                                    taskId: tasks.currentTask?.id!,
                                                    ...data,
                                                })
                                            }
                                            readOnly={!isSavable}
                                        />
                                    </>
                                )}
                                <DimrLinkedActivitiesDialog
                                    attributes={attributes}
                                    isLoading={isLoading}
                                    open={isActivityDialogOpen}
                                    onClose={() => {
                                        setActivityDialogOpen(false);
                                        refetchContext();
                                    }}
                                    readOnly={!isSavable}
                                />
                            </Stack>
                        }
                    />
                }
                Body={
                    <>
                        <LinkedDocumentsPanel
                            type={EntityType.Dimr}
                            dimr={
                                variant === DIMRPageVariant.READ
                                    ? pageData.dimrData
                                    : {
                                          rpAssessments: [{ activity: pageData.activityData }],
                                          workDosePlanningVersions: [],
                                      }
                            }
                            collapsed={!hasOnlyOneRpAssessment}
                            contentModifier={(content) =>
                                content.map((e) => ({
                                    ...e,
                                    defaultExpanded:
                                        hasOnlyOneRpAssessment && !e.async && e.type === EntityType.Activity,
                                }))
                            }
                        />
                        <ValidationErrorsPanel schema={uiSchemaDimr} customResolver={customErrorResolver} />
                        <FieldsAttributesContextProvider value={attributes}>
                            <Box mb={2}>
                                {variant === DIMRPageVariant.READ && (
                                    <CommentList
                                        documentDetails={[
                                            {
                                                documentType: DocumentType.DIMR,
                                                documentId: pageData.dimrData.id,
                                            },
                                        ]}
                                        currentTask={tasks.currentTask}
                                        disabled={isLoading}
                                        collapsed={hasNoRPOrRSOComment}
                                    />
                                )}
                            </Box>
                            <DynamicValidationFormWrapper
                                setFocus={setFocus}
                                depsContextRefresh={depsContextRefresh}
                                isContextLoading={isContextLoading}
                                refetchContext={refetchContext}
                            >
                                <FormGenerator rootElement={uiSchemaDimr} form={form} disabled={isLoading} />
                            </DynamicValidationFormWrapper>
                            {variant === DIMRPageVariant.READ && (
                                <WorkflowHistory documentType={DocumentType.DIMR} documentId={pageData.dimrData.id} />
                            )}
                        </FieldsAttributesContextProvider>
                    </>
                }
                Drawer={
                    variant === DIMRPageVariant.READ ? (
                        <TaskHistory
                            tasks={tasks}
                            disabled={isLoading}
                            withUnsavedChangesPrompt={withUnsavedChangesPrompt}
                            onTaskChange={() => form.reset()}
                            onTaskUpdate={refreshData}
                        />
                    ) : null
                }
            />
        </FormProvider>
    );
};
