import { DimrStatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const DIMRStatusColorMapping: Record<DimrStatusCode, StatusColor> = {
    [DimrStatusCode.DRAFT]: StatusColor.PRIMARY,
    [DimrStatusCode.RP_VALIDATION]: StatusColor.WARNING,
    [DimrStatusCode.VALIDATING]: StatusColor.WARNING,
    [DimrStatusCode.WAITING_APPROVAL]: StatusColor.WARNING,
    [DimrStatusCode.APPROVED]: StatusColor.SUCCESS,
    [DimrStatusCode.IN_PROGRESS]: StatusColor.SUCCESS,
    [DimrStatusCode.LATE]: StatusColor.WARNING,
    [DimrStatusCode.WAITING_FEEDBACK]: StatusColor.WARNING,
    [DimrStatusCode.WAITING_WDP_CLOSURE]: StatusColor.WARNING,
    [DimrStatusCode.WAITING_DIMR_CLOSURE]: StatusColor.WARNING,
    [DimrStatusCode.CLOSED]: StatusColor.NORMAL,
    [DimrStatusCode.CANCELLED]: StatusColor.ERROR,
    [DimrStatusCode.REJECTED]: StatusColor.ERROR,
    [DimrStatusCode.OBSOLETE]: StatusColor.ERROR,
};
