import { useCallback } from 'react';
import { TaskDTO } from '../../api/dto';
import { useApplicationSettings } from '../application/ApplicationSettingsProvider';

export const useTaskCanBeCompleted = () => {
    const { currentUser } = useApplicationSettings();
    return useCallback(
        (task: TaskDTO) => task.taskActive && task.assignees.some((assignee) => assignee.id === currentUser.id),
        [currentUser]
    );
};
