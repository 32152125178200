/* eslint-disable @typescript-eslint/no-use-before-define */
import { Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { createIs37, getIs37Pdf, updateIs37 } from '../../api/documents/is37s';
import {
    DocumentActionDTO,
    DocumentType,
    IS37ChangeRequestContextReadDTO,
    IS37ChangeRequestReadDTO,
    IS37ContextReadDTO,
    IS37ReadDTO,
    IS37StatusCode,
    TaskType,
} from '../../api/dto';
import { useApplicationSettings } from '../../components/application/ApplicationSettingsProvider';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../components/documents/DocumentButtonsStack';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { Is37WarningPanel } from '../../components/documents/Is37WarningPanel';
import { LinkedDocumentsPanel } from '../../components/documents/LinkedDocumentsPanel';
import { ValidationErrorsPanel } from '../../components/ValidationErrorsPanel';
import { WorkflowHistory } from '../../components/WorkflowHistory';
import { useUnsavedChangesPrompt } from '../../hooks/useUnsavedChangesPrompt';
import {
    areIs37LocationsPartOfActivityFacility,
    makeIS37ChangeRequestDefaultValues,
    makeIs37DefaultValuesFromClonedIs37,
    makeIs37DefaultValuesFromLinkedActivity,
    makeIs37Values,
    mapIs37ToUpdateDTO,
} from '../../lib/records/documents/is37';
import { IS37ContentHeader } from '../../pages/documents/is37/IS37ContentHeader';
import { IS37PageData } from '../../pages/documents/is37/IS37PageData';
import { IS37PageVariant } from '../../pages/documents/is37/IS37PageVariant';
import { getRoute, ROUTES } from '../../routes/routes';
import { FormGenerator } from '../FormGenerator';

import { CommentList } from '../../components/comments/CommentList';
import { IS37ChangeRequestDialog } from '../../components/documents/IS37ChangeRequestDialog';
import { LocalizedDocumentPdfButton } from '../../components/documents/LocalizedDocumentPdfButton';
import { InactiveActivityWarning } from '../../components/InactiveActivityWarning';
import { useTaskContext } from '../../components/TaskContext';
import { TaskActions } from '../../components/tasks/TaskActions';
import { TaskHistory } from '../../components/tasks/TaskHistory';
import { useTasks } from '../../components/tasks/useTasks';
import { useDocumentCreationToast } from '../../hooks/useDocumentCreationToast';
import { useDocumentSaveErrorCallback } from '../../hooks/validation';
import { artificialDelay } from '../../lib/delay';
import { EntityType } from '../../lib/information/types/EntityType';
import { FieldsAttributesContextProvider } from '../FieldsAttributesContext';
import { PreciseRulesDTO } from '../rule/deserializer';
import { useFormRules } from '../rule/useFormRules';
import { useIsSavableFromRules } from '../rule/useIsSavableFromRules';
import { uiSchemaIs37For } from '../schemas/documents/is37';

interface Is37FormProps {
    data: IS37PageData;
    rules: PreciseRulesDTO<IS37ContextReadDTO>;
    changeRequestRules: PreciseRulesDTO<IS37ChangeRequestContextReadDTO>;
    refreshData: () => void;
}

export const Is37Form: React.FC<Is37FormProps> = ({ data: pageData, rules, changeRequestRules, refreshData }) => {
    const { withDispatchTaskEvent } = useTaskContext();
    const intl = useIntl();
    const { variant, activityData } = pageData;
    const { currentUser } = useApplicationSettings();
    const navigate = useNavigate();
    const defaultValues = useMemo(
        () =>
            pageData.variant === IS37PageVariant.READ
                ? makeIs37Values(pageData.is37Data, currentUser)
                : pageData.variant === IS37PageVariant.CLONE
                ? makeIs37DefaultValuesFromClonedIs37(pageData.is37Data, pageData.activityData, currentUser)
                : makeIs37DefaultValuesFromLinkedActivity(activityData, currentUser),
        [pageData, currentUser]
    );

    const tasks = useTasks(
        pageData.variant === IS37PageVariant.READ
            ? {
                  documentDetails: [
                      { documentId: pageData.is37Data.id, documentType: DocumentType.IS37 },
                      ...(pageData.is37Data.changeRequests
                          ? pageData.is37Data.changeRequests.map((changeRequest) => ({
                                documentId: changeRequest.id,
                                documentType: DocumentType.IS37_CR,
                            }))
                          : []),
                  ],
                  onTaskSubmit: (taskHandler) => {
                      clearErrors();
                      return handleSubmit((data) => taskHandler(data))();
                  },
                  onTaskError: (response: AxiosResponse) =>
                      handleFormSaveError(response, { validate: true, submit: false }),
                  refreshData,
              }
            : undefined
    );
    const context: IS37ContextReadDTO | null = useMemo(
        () =>
            rules.context !== null
                ? {
                      ...rules.context,
                      documentAction: variant !== IS37PageVariant.READ ? DocumentActionDTO.CREATE : null,
                      task: tasks.currentTask?.type?.type ?? null,
                  }
                : null,
        [rules.context, tasks.currentTask]
    );

    const { form, attributes } = useFormRules<Omit<IS37ReadDTO, 'id' | 'version'>>({
        formProps: {
            mode: 'onSubmit',
            defaultValues: {
                ...defaultValues,
            },
        },
        rules,
        originalValues: defaultValues,
        context: context as any,
    });
    const { handleSubmit, clearErrors, formState, watch } = form;
    const [openCRDialog, setOpenCRDialog] = useState(false);
    const activity = watch('activity');
    const locations = watch('locations');

    const handleFormSaveError = useDocumentSaveErrorCallback(form, DocumentType.IS37);

    const sendDocumentCreationToast = useDocumentCreationToast('is37.name');

    const { mutateAsync: saveIs37, isLoading: isSaveLoading } = useMutation(
        ({ data: formData }: { data: any }) => {
            if (variant === IS37PageVariant.READ) {
                if (tasks.currentTask === null) {
                    throw new Error();
                }
                return updateIs37({
                    id: pageData.is37Data.id,
                    taskId: tasks.currentTask.id,
                    data: mapIs37ToUpdateDTO(formData, false),
                });
            } else {
                return createIs37({ data: mapIs37ToUpdateDTO(formData, true) }).then(artificialDelay);
            }
        },
        {
            onSuccess: (result) => {
                if (variant === IS37PageVariant.READ) {
                    refreshData();
                } else {
                    sendDocumentCreationToast();
                    navigate(
                        getRoute({
                            path: ROUTES.is37.view,
                            params: { id: String(result.id) },
                        }),
                        {
                            replace: true,
                        }
                    );
                }
            },
            onError: (response: AxiosResponse) => handleFormSaveError(response),
        }
    );

    const canClone = defaultValues.availableActions.canClone;

    const { isDirty: formIsDirty } = formState;
    const isDirty = formIsDirty || variant !== IS37PageVariant.READ; // Create/clone form is always dirty

    const isLoading = isSaveLoading || tasks.disabled;
    const withUnsavedChangesPrompt = useUnsavedChangesPrompt({
        when: !isLoading && formIsDirty,
    });
    const refreshDataWithUnsavedChangesPrompt = () => withUnsavedChangesPrompt(refreshData);

    const disablementAttributes = attributes['disablement.doneBy'];
    const disablementValue =
        disablementAttributes?.editable || (pageData.variant === IS37PageVariant.READ && pageData.is37Data.disablement);
    const recommissioningAttributes = attributes['recommissioning.doneBy'];
    const recommissioningValue =
        recommissioningAttributes?.editable ||
        (pageData.variant === IS37PageVariant.READ && pageData.is37Data.recommissioning);
    const showDisablePanel = !!disablementValue || (context ? context.task === TaskType.DISABLE_SENSORS : false);
    const showRecommissionPanel =
        !!recommissioningValue || (context ? context.task === TaskType.RECOMMISSION_SENSORS : false);
    const canCreateChangeRequest = context ? context.task === TaskType.CREATE_IS37_CHANGE_REQUEST : false;
    const changeRequests: IS37ChangeRequestReadDTO[] = useMemo(
        () => (pageData.variant === IS37PageVariant.READ ? pageData.is37Data.changeRequests : []),
        [pageData]
    );
    const uiSchemaIs37 = useMemo(
        () =>
            uiSchemaIs37For(
                showDisablePanel,
                showRecommissionPanel,
                intl,
                changeRequests,
                setOpenCRDialog,
                canCreateChangeRequest,
                areIs37LocationsPartOfActivityFacility({ activity, locations }),
                variant !== IS37PageVariant.READ || pageData.is37Data.status.code === IS37StatusCode.DRAFT
            ),
        [
            showDisablePanel,
            showRecommissionPanel,
            disablementValue,
            recommissioningValue,
            intl,
            changeRequests,
            setOpenCRDialog,
            canCreateChangeRequest,
            activity,
            locations,
            variant,
            pageData,
        ]
    );

    const handleCloseCRDialog = () => {
        setOpenCRDialog(false);
    };

    // Hardcoded list of tasks that will throw a validation error is the save button is used (see `constraintNoSave`)
    const isCompleteOnly =
        tasks.currentTask &&
        [
            TaskType.DISABLE_SENSORS,
            TaskType.RECOMMISSION_SENSORS,
            TaskType.CHANGE_ALARM_TYPE,
            TaskType.ADD_IS37_SENSORS,
            TaskType.SCHEDULE_RECOMMISSIONING,
        ].includes(tasks.currentTask?.type?.type);
    const isSavable = useIsSavableFromRules(attributes) && !isCompleteOnly;
    const isCompletable = tasks.isLoading || !!tasks.assignableTasks.length;

    return (
        <FormProvider {...form}>
            <DocumentPageStructure
                isLoading={isLoading}
                Header={
                    <IS37ContentHeader
                        data={pageData}
                        editable={isSavable || isCompletable}
                        isLoading={isLoading}
                        onRefreshClick={
                            variant === IS37PageVariant.READ ? refreshDataWithUnsavedChangesPrompt : undefined
                        }
                        Left={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {tasks.currentTask !== null && (
                                    <TaskActions
                                        task={tasks.currentTask}
                                        onValidate={tasks.validate}
                                        onSubmit={tasks.perform}
                                        disabled={isLoading}
                                        getValues={form.getValues}
                                        context={context}
                                        isDirty={formIsDirty}
                                    />
                                )}
                                {isSavable && (
                                    <DocumentButtonsStack.Action
                                        {...(variant === IS37PageVariant.CREATE || variant === IS37PageVariant.CLONE
                                            ? COMMON_DOCUMENT_BUTTONS.CREATE
                                            : COMMON_DOCUMENT_BUTTONS.SAVE)}
                                        onClick={withDispatchTaskEvent(() => {
                                            clearErrors();
                                            return handleSubmit((data) => saveIs37({ data }))();
                                        })}
                                        disabled={!isDirty}
                                        disablingReason={intl.formatMessage({
                                            id: 'document.form.disabledNoChange',
                                        })}
                                        important={!tasks.currentTask}
                                    />
                                )}
                                {pageData.variant !== IS37PageVariant.READ && pageData.activityData !== null ? (
                                    <>{!pageData.activityData.isActive && <InactiveActivityWarning />}</>
                                ) : null}
                            </Stack>
                        }
                        Right={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {variant === IS37PageVariant.READ && canClone && (
                                    <DocumentButtonsStack.Action
                                        {...COMMON_DOCUMENT_BUTTONS.CLONE}
                                        onClick={() => {
                                            window.open(
                                                getRoute({
                                                    path: ROUTES.is37.clone,
                                                    params: { id: String(pageData.is37Data.id) },
                                                })
                                            );
                                        }}
                                        disabled={formIsDirty}
                                        disablingReason={intl.formatMessage({ id: 'document.form.disabledUnsaved' })}
                                    />
                                )}
                                {pageData.variant === IS37PageVariant.READ && !!pageData.is37Data.pdf && (
                                    <LocalizedDocumentPdfButton
                                        queryKey={['is37', pageData.is37Data.id]}
                                        fetchPdf={(language) =>
                                            getIs37Pdf({
                                                id: pageData.is37Data.id,
                                                langCode: language,
                                            })
                                        }
                                        isPdfPreview={pageData.is37Data.pdf.isPdfPreview}
                                        canGenerateInBothLanguages={true}
                                        dirty={formIsDirty}
                                    />
                                )}
                            </Stack>
                        }
                    />
                }
                Body={
                    <>
                        <LinkedDocumentsPanel
                            type={EntityType.Is37}
                            is37={
                                variant === IS37PageVariant.READ
                                    ? pageData.is37Data
                                    : { activity: pageData.activityData, firePermit: null, workOrderId: null }
                            }
                        />
                        <ValidationErrorsPanel schema={uiSchemaIs37} />
                        <FieldsAttributesContextProvider value={attributes}>
                            <FormGenerator rootElement={uiSchemaIs37} form={form} disabled={isLoading} />
                            {variant === IS37PageVariant.READ && (
                                <WorkflowHistory documentType={DocumentType.IS37} documentId={pageData.is37Data.id} />
                            )}
                            {variant === IS37PageVariant.READ && (
                                <CommentList
                                    documentDetails={[
                                        { documentType: DocumentType.IS37, documentId: pageData.is37Data.id },
                                        ...(pageData.is37Data.changeRequests
                                            ? pageData.is37Data.changeRequests.map((changeRequest) => ({
                                                  documentType: DocumentType.IS37_CR,
                                                  documentId: changeRequest.id,
                                              }))
                                            : []),
                                    ]}
                                    currentTask={tasks.currentTask}
                                    disabled={isLoading}
                                />
                            )}
                        </FieldsAttributesContextProvider>
                        <Is37WarningPanel />
                        {variant === IS37PageVariant.READ && (
                            <IS37ChangeRequestDialog
                                is37={pageData.is37Data}
                                onCancel={handleCloseCRDialog}
                                onClose={handleCloseCRDialog}
                                open={openCRDialog}
                                refreshData={refreshData}
                                defaultValues={makeIS37ChangeRequestDefaultValues(pageData.is37Data)}
                                rules={changeRequestRules}
                            />
                        )}
                    </>
                }
                Drawer={
                    variant === IS37PageVariant.READ ? (
                        <TaskHistory
                            tasks={tasks}
                            disabled={isLoading}
                            withUnsavedChangesPrompt={withUnsavedChangesPrompt}
                            onTaskChange={() => form.reset()}
                            onTaskUpdate={refreshData}
                        />
                    ) : null
                }
            />
        </FormProvider>
    );
};
