import { Assignment, Functions, Help, Settings } from '@mui/icons-material';
import { Alert, Box, Stack, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, IntlShape, MessageDescriptor, useIntl } from 'react-intl';
import {
    getDimrAlaraLevels,
    getDimrAreaClassification,
    getDimrRequirementPeriods,
    getEquipmentJobCodes,
    getGeneralJobCodes,
    getPersonsRpo,
    getPersonsRso,
    getPersonsRssoRpeByActivity,
    getPersonsRssoRpeByFacility,
    rpaAttachmentsApi,
} from '../../../api/documents/dimrs';
import {
    ActivityDTO,
    AttachmentDTO,
    ListOfValuesReadDTO,
    LocationDTO,
    PersonReadDTO,
    RpAssessmentReadDTO,
    TaskDTO,
    TaskType,
    WdpVersionGridReadDTO,
    WdpWorkStepReferencedReadDTO,
} from '../../../api/dto';
import { DocumentButtonsStack } from '../../../components/documents/DocumentButtonsStack';
import { DoseReportButton } from '../../../components/documents/DoseReportButton';
import {
    LinkedDocumentDataAsyncProps,
    LinkedDocumentsContent,
} from '../../../components/documents/LinkedDocumentsContent';
import { fetchPeopleByName } from '../../../lib/api';
import { EntityType } from '../../../lib/information/types/EntityType';
import { getLovLabel } from '../../../lib/label';
import { translateValue } from '../../../lib/language';
import { formatVersionedId } from '../../../lib/records/documents/versioned';
import { AttachmentsAction } from '../../../pages/documents/ndc/AttachmentsAction';
import { getRoute, ROUTES } from '../../../routes/routes';
import { FetchUseCase } from '../../fields/AsyncAutocompleteElement';
import { useFieldsAttributesContext } from '../../FieldsAttributesContext';
import { LayoutGridItem, TFormElement } from '../../types';
import { UiSchemaType } from '../../UiSchemaType';
import { locationSchema } from '../location';
import { personSchema } from '../person';
import { prefilledLovSchema } from '../prefilledLovSchema';
import { numberWithUnitSchemaFor } from '../unit';
import { valueSchema } from '../value';
import { wdpSchema } from '../wdp';

interface RpAssessmentAttachmentsProps {
    path: string;
    disabled: boolean;
    currentTask: TaskDTO | null;
}

interface RpAssessmentDoseReportProps {
    path: string;
    disabled: boolean;
}

const RpAssessmentAttachments: React.FC<RpAssessmentAttachmentsProps> = ({ path, disabled, currentTask }) => {
    const { watch } = useFormContext();
    const rpaId: number | undefined = watch(`${path}.id`);
    const attachments: AttachmentDTO[] = watch(`${path}.attachments`) ?? [];
    const [[attributes]] = useFieldsAttributesContext([[path]]);
    const readOnly = !attributes?.editable;
    return rpaId !== undefined ? (
        <DocumentButtonsStack disabled={disabled} large={true}>
            <AttachmentsAction
                attachments={attachments}
                linkAttachments={(newAttachments) =>
                    rpaAttachmentsApi.linkAttachments({
                        id: rpaId,
                        taskId: currentTask!.id,
                        ...newAttachments,
                    })
                }
                unlinkAttachments={(newAttachments) =>
                    rpaAttachmentsApi.unlinkAttachments({
                        id: rpaId,
                        taskId: currentTask!.id,
                        ...newAttachments,
                    })
                }
                updateAttachment={(data) =>
                    rpaAttachmentsApi.updateAttachment({
                        id: rpaId,
                        taskId: currentTask!.id,
                        ...data,
                    })
                }
                readOnly={
                    readOnly ||
                    currentTask === null ||
                    ![TaskType.EDIT, TaskType.SUBMIT].includes(currentTask.type.type)
                }
            />
        </DocumentButtonsStack>
    ) : null;
};

const RpAssessmentDoseReport: React.FC<RpAssessmentDoseReportProps> = ({ path, disabled }) => {
    const { watch } = useFormContext();
    const activityId: number | undefined = watch(`${path}.activity.id`);
    return activityId !== undefined ? (
        <DocumentButtonsStack disabled={disabled} large={true}>
            <DoseReportButton activityId={activityId} />
        </DocumentButtonsStack>
    ) : null;
};

interface RpAssessmentLinkedActivityProps {
    path: string;
}

const RpAssessmentLinkedActivity: React.FC<RpAssessmentLinkedActivityProps> = ({ path }) => {
    const { watch } = useFormContext();
    const wdps: WdpVersionGridReadDTO[] | undefined = watch('workDosePlanningVersions');
    const rpAssessment: RpAssessmentReadDTO | undefined = watch(path);
    const possibleWdps = useMemo(
        () =>
            rpAssessment?.wdpWorkSteps?.flatMap(
                (step) =>
                    wdps?.filter(
                        (wdp) => wdp.wdpMasterId === step.wdpMasterId && wdp.versionNumber === step.versionNumber
                    ) ?? []
            ) ?? [],
        [rpAssessment, wdps]
    );
    const wdp: WdpVersionGridReadDTO | undefined = useMemo(
        () =>
            new Set<string>(
                possibleWdps.map(({ wdpMasterId, versionNumber }) => JSON.stringify({ wdpMasterId, versionNumber }))
            ).size === 1
                ? possibleWdps[0]
                : undefined,
        [possibleWdps]
    );
    return rpAssessment ? (
        <LinkedDocumentsContent
            documents={[
                { type: EntityType.Activity, activity: rpAssessment.activity, defaultExpanded: true },
                ...(wdp !== undefined ? [{ type: EntityType.Wdp, wdp } satisfies LinkedDocumentDataAsyncProps] : []),
            ]}
        />
    ) : null;
};

interface EstimatedValuesAlertProps {
    path: string;
}

const EstimatedValuesAlert: React.FC<EstimatedValuesAlertProps> = ({ path }) => {
    const { watch } = useFormContext();
    const rpAssessment: RpAssessmentReadDTO | undefined = watch(path);
    const valuesForcedManual = rpAssessment?.valuesForcedManual;
    const hasLinkedWdp = rpAssessment?.wdpWorkSteps.length !== 0;

    return (
        <Alert severity="info">
            <FormattedMessage
                id="dimr.field.rpa.estimatedValues.info"
                values={{
                    valuesForcedManual: valuesForcedManual,
                    hasLinkedWdp: hasLinkedWdp,
                }}
            />
        </Alert>
    );
};

export const PcrNotificationTooltip: React.FC = () => {
    const intl = useIntl();
    return (
        <Tooltip title={intl.formatMessage({ id: 'dimr.pcrNotification.info' })} placement="right">
            <Help
                sx={{
                    color: (theme) => theme.palette.grey[600],
                    cursor: 'help',
                }}
            />
        </Tooltip>
    );
};
const uiSchemaHistoricalComments = (label: MessageDescriptor['id'], intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            element: {
                type: UiSchemaType.INPUT,
                label: intl.formatMessage({
                    id: label,
                }),
                path: 'text',
                typeProperties: {
                    multiline: true,
                },
            },
        },
    ],
});
export const dimrFormSchema = (
    {
        dimrId,
        currentTask,
        facilities,
        workflowLocation,
    }: {
        dimrId: number | null;
        currentTask: TaskDTO | null;
        facilities: ListOfValuesReadDTO[];
        workflowLocation: LocationDTO | null;
    },
    intl: IntlShape
): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'document.panel.general' }),
            typeProperties: {
                icon: <Settings color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_RESPONSIVE,
                typeProperties: {
                    direction: {
                        xs: 'column',
                    },
                },
                elements: [
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'document.field.title' }),
                            path: 'title',
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'document.field.description' }),
                            path: 'description',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.AUTOCOMPLETE,
                            path: 'mainFacility',
                            label: intl.formatMessage({ id: 'dimr.field.mainFacility' }),
                            typeProperties: {
                                options: facilities,
                                getOptionLabel: (value: ListOfValuesReadDTO) =>
                                    getLovLabel(translateValue(value, intl.locale)),
                                idField: 'code' satisfies keyof ListOfValuesReadDTO,
                            },
                        },
                    },

                    ...(workflowLocation != null
                        ? [
                              {
                                  element: {
                                      type: UiSchemaType.CUSTOM,
                                      Component: () => (
                                          <Alert severity="info">
                                              <Stack direction="column">
                                                  <Box>
                                                      <FormattedMessage
                                                          id="dimr.field.locationWithHighestArea"
                                                          values={{
                                                              locationName: workflowLocation.impactName,
                                                              level: workflowLocation?.highestAreaClassification
                                                                  ? getLovLabel(
                                                                        translateValue(
                                                                            workflowLocation.highestAreaClassification,
                                                                            intl.locale
                                                                        )
                                                                    )
                                                                  : undefined,
                                                          }}
                                                      />
                                                  </Box>
                                              </Stack>
                                          </Alert>
                                      ),
                                  },
                              } satisfies LayoutGridItem,
                          ]
                        : []),
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    md: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: personSchema({
                                        label: intl.formatMessage({ id: 'dimr.field.owner' }),
                                        path: 'owner',
                                    }),
                                },
                                {
                                    element: personSchema({
                                        label: intl.formatMessage({ id: 'dimr.field.rsoExperimentRso' }),
                                        path: 'rso',
                                        watchPaths: ['mainFacility', 'owner'],
                                        fetchEndpoint: async (searchTerm, exactId, idField, values) =>
                                            values != null && values[0] != null && values[1] != null
                                                ? getPersonsRso({
                                                      facilityCode: (values[0] as ListOfValuesReadDTO).code,
                                                      ownerId: (values[1] as PersonReadDTO).id,
                                                  })
                                                : fetchPeopleByName(searchTerm, exactId),
                                        fetchOnOpen: true,
                                        fetchOnKeystroke: false,
                                        preserveOrder: true,
                                    }),
                                },
                            ],
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    md: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: personSchema({
                                        label: intl.formatMessage({ id: 'dimr.field.rpo' }),
                                        path: 'rpo',
                                        fetchEndpoint: () => getPersonsRpo(),
                                        fetchOnLoad: true,
                                        fetchOnKeystroke: false,
                                    }),
                                },
                                {
                                    element: personSchema({
                                        label: intl.formatMessage({ id: 'dimr.field.rssoRpe' }),
                                        path: 'rssoRpe',
                                        watchPaths: ['mainFacility', 'owner'],
                                        fetchEndpoint: async (searchTerm, exactId, idField, values) =>
                                            values != null && values[0] != null && values[1] != null
                                                ? getPersonsRssoRpeByFacility({
                                                      facilityCode: (values[0] as ListOfValuesReadDTO).code,
                                                      ownerId: (values[1] as PersonReadDTO).id,
                                                  })
                                                : fetchPeopleByName(searchTerm, exactId),
                                        fetchOnOpen: true,
                                        fetchOnKeystroke: false,
                                        preserveOrder: true,
                                    }),
                                },
                            ],
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.CHECKBOX,
                            label: intl.formatMessage({ id: 'dimr.field.pcrNotification' }),
                            path: 'pcrNotification',
                            typeProperties: {
                                endAdornment: PcrNotificationTooltip,
                            },
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'dimr.field.riskAnalysisAssessment' }),
                            path: 'riskAnalysisAssessment',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    md: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: {
                                        type: UiSchemaType.DATE,
                                        label: intl.formatMessage({ id: 'dimr.field.workStartDate' }),
                                        path: 'workStartDate',
                                    },
                                },
                                {
                                    element: {
                                        type: UiSchemaType.DATE,
                                        label: intl.formatMessage({ id: 'dimr.field.workEndDate' }),
                                        path: 'workEndDate',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        element: wdpSchema(
                            {
                                label: intl.formatMessage({ id: 'dimr.field.wdps' }),
                                path: 'workDosePlanningVersions',
                                multiple: true,
                                create:
                                    dimrId !== null
                                        ? getRoute({ path: ROUTES.wdp.create, queryParams: { dimr: dimrId } })
                                        : undefined,
                            },
                            intl
                        ),
                    },
                    {
                        element: {
                            type: UiSchemaType.CHECKBOX,
                            label: intl.formatMessage({ id: 'dimr.field.bypassWorkflow' }),
                            path: 'bypassWorkflow',
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.CHECKBOX,
                            label: intl.formatMessage({ id: 'dimr.field.forceReapproval' }),
                            path: 'forceReapproval',
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'dimr.panel.calculated' }),
            typeProperties: {
                icon: <Functions color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_RESPONSIVE,
                typeProperties: {
                    direction: {
                        xs: 'column',
                    },
                },
                elements: [
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    md: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: numberWithUnitSchemaFor(
                                        {
                                            label: intl.formatMessage({ id: 'dimr.field.maxEstAirborneCont' }),
                                            path: 'maxEstAirborneCont',
                                            unit: intl.formatMessage({ id: 'document.unit.contaminationAirborne' }),
                                            recipe: intl.formatMessage({ id: 'dimr.recipe.maxEstAirborneCont' }),
                                            integer: false,
                                            signed: false,
                                        },
                                        intl
                                    ),
                                },
                                {
                                    element: numberWithUnitSchemaFor(
                                        {
                                            label: intl.formatMessage({ id: 'dimr.field.maxEstSurfaceCont' }),
                                            path: 'maxEstSurfaceCont',
                                            unit: intl.formatMessage({ id: 'document.unit.contaminationSurface' }),
                                            recipe: intl.formatMessage({ id: 'dimr.recipe.maxEstSurfaceCont' }),
                                            integer: false,
                                            signed: false,
                                        },
                                        intl
                                    ),
                                },
                            ],
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    md: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: numberWithUnitSchemaFor(
                                        {
                                            label: intl.formatMessage({ id: 'dimr.field.maxEstDoseRate' }),
                                            path: 'maxEstDoseRate',
                                            unit: intl.formatMessage({ id: 'document.unit.microSievertPerHour' }),
                                            recipe: intl.formatMessage({ id: 'dimr.recipe.maxEstDoseRate' }),
                                            integer: true,
                                            signed: false,
                                        },
                                        intl
                                    ),
                                },
                                {
                                    element: numberWithUnitSchemaFor(
                                        {
                                            label: intl.formatMessage({ id: 'dimr.field.maxAvgEstDoseRate' }),
                                            path: 'maxAvgEstDoseRate',
                                            unit: intl.formatMessage({ id: 'document.unit.microSievertPerHour' }),
                                            recipe: intl.formatMessage({ id: 'dimr.recipe.maxAvgEstDoseRate' }),
                                            integer: false,
                                            signed: false,
                                        },
                                        intl
                                    ),
                                },
                            ],
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    md: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: numberWithUnitSchemaFor(
                                        {
                                            label: intl.formatMessage({ id: 'dimr.field.estCollectiveDose' }),
                                            path: 'calculatedEstColDose', // Not to be confused with `estCollectiveDose` (which on the other hand represents a manually entered value)
                                            unit: intl.formatMessage({ id: 'document.unit.personMicroSievert' }),
                                            recipe: intl.formatMessage({ id: 'dimr.recipe.estCollectiveDose' }),
                                            integer: true,
                                            signed: false,
                                        },
                                        intl
                                    ),
                                },
                                {
                                    element: numberWithUnitSchemaFor(
                                        {
                                            label: intl.formatMessage({ id: 'dimr.field.maxEstIndividualDose' }),
                                            path: 'calculatedMaxEstIndDose', // (same)
                                            unit: intl.formatMessage({ id: 'document.unit.microSievert' }),
                                            recipe: intl.formatMessage({ id: 'dimr.recipe.maxEstIndividualDose' }),
                                            integer: true,
                                            signed: false,
                                        },
                                        intl
                                    ),
                                },
                            ],
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    md: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: numberWithUnitSchemaFor(
                                        {
                                            label: intl.formatMessage({ id: 'dimr.field.actualCollectiveDose' }),
                                            path: 'actualCollectiveDose',
                                            unit: intl.formatMessage({ id: 'document.unit.personMicroSievert' }),
                                            recipe: intl.formatMessage({ id: 'dimr.recipe.actualCollectiveDose' }),
                                            integer: true,
                                            signed: false,
                                        },
                                        intl
                                    ),
                                },
                                {
                                    element: numberWithUnitSchemaFor(
                                        {
                                            label: intl.formatMessage({ id: 'dimr.field.actualMaxIndividualDose' }),
                                            path: 'actualMaxIndividualDose',
                                            unit: intl.formatMessage({ id: 'document.unit.microSievert' }),
                                            recipe: intl.formatMessage({ id: 'dimr.recipe.actualMaxIndividualDose' }),
                                            integer: true,
                                            signed: false,
                                        },
                                        intl
                                    ),
                                },
                            ],
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.DATE,
                            label: intl.formatMessage({ id: 'dimr.field.actualDosesDate' }),
                            path: 'actualDosesDate',
                        },
                    },
                    {
                        element: prefilledLovSchema({
                            label: intl.formatMessage({ id: 'document.field.alaraLevel' }),
                            path: 'alaraLevel',
                            fetchOptions: getDimrAlaraLevels,
                        }),
                    },
                    {
                        element: {
                            type: UiSchemaType.CHECKBOX,
                            label: intl.formatMessage({ id: 'dimr.field.forceAlara' }),
                            path: 'alaraLevelForced',
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    md: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: numberWithUnitSchemaFor(
                                        {
                                            label: intl.formatMessage(
                                                { id: 'dimr.manualTotal' },
                                                { field: intl.formatMessage({ id: 'dimr.field.estCollectiveDose' }) }
                                            ),
                                            path: 'estCollectiveDose',
                                            unit: intl.formatMessage({ id: 'document.unit.personMicroSievert' }),
                                            integer: true,
                                            signed: false,
                                        },
                                        intl
                                    ),
                                },
                                {
                                    element: numberWithUnitSchemaFor(
                                        {
                                            label: intl.formatMessage(
                                                { id: 'dimr.manualTotal' },
                                                { field: intl.formatMessage({ id: 'dimr.field.maxEstIndividualDose' }) }
                                            ),
                                            path: 'maxEstIndividualDose',
                                            unit: intl.formatMessage({ id: 'document.unit.microSievert' }),
                                            integer: true,
                                            signed: false,
                                        },
                                        intl
                                    ),
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'dimr.panel.rpAssessments' }),
            typeProperties: {
                icon: <Assignment color="action" />,
                disableGutters: true,
            },
            element: {
                type: UiSchemaType.FIELD_ARRAY_TABS,
                path: 'rpAssessments',
                tabLabel: (rpAssessment: RpAssessmentReadDTO) => rpAssessment?.activity?.title || '',
                element: {
                    type: UiSchemaType.LAYOUT_RESPONSIVE,
                    typeProperties: {
                        direction: {
                            xs: 'column',
                        },
                    },
                    elements: [
                        {
                            element: {
                                type: UiSchemaType.CUSTOM,
                                Component: RpAssessmentLinkedActivity as any,
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.LAYOUT_RESPONSIVE,
                                typeProperties: {
                                    direction: {
                                        xs: 'column',
                                        md: 'row',
                                    },
                                },
                                elements: [
                                    {
                                        element: personSchema({
                                            label: intl.formatMessage({ id: 'dimr.field.rssoRpe' }),
                                            path: 'rssoRpe',
                                            watchPaths: (path: string) => [
                                                `${path.split('.').slice(0, 2).join('.')}.activity`,
                                            ],
                                            fetchEndpoint: async (searchTerm, exactId, idField, values) =>
                                                getPersonsRssoRpeByActivity({
                                                    activityId: (values as [ActivityDTO])[0].id,
                                                }),
                                            fetchOnOpen: true,
                                            fetchOnKeystroke: false,
                                            preserveOrder: true,
                                        }),
                                    },
                                    {
                                        element: valueSchema(
                                            {
                                                label: intl.formatMessage({ id: 'dimr.field.rpa.rpPresence' }),
                                                path: 'rpPresence',
                                                fetchOptions: getDimrRequirementPeriods,
                                            },
                                            intl.locale
                                        ),
                                    },
                                ],
                            },
                        },
                        {
                            element: locationSchema({
                                label: intl.formatMessage({ id: 'document.field.locations' }),
                                path: 'locations',
                                multiple: true,
                            }),
                        },
                        {
                            element: {
                                type: UiSchemaType.LAYOUT_RESPONSIVE,
                                typeProperties: {
                                    direction: {
                                        xs: 'column',
                                        md: 'row',
                                    },
                                },
                                elements: [
                                    {
                                        element: valueSchema(
                                            {
                                                label: intl.formatMessage({ id: 'dimr.field.rpa.generalJobCode' }),
                                                path: 'generalJobCode',
                                                fetchOptions: getGeneralJobCodes,
                                            },
                                            intl.locale
                                        ),
                                    },
                                    {
                                        element: valueSchema(
                                            {
                                                label: intl.formatMessage({ id: 'dimr.field.rpa.equipmentJobCode' }),
                                                path: 'equipmentJobCode',
                                                fetchOptions: getEquipmentJobCodes,
                                            },
                                            intl.locale
                                        ),
                                    },
                                ],
                            },
                        },
                        {
                            element: personSchema({
                                label: intl.formatMessage({ id: 'dimr.field.rpa.pcrs' }),
                                path: 'rpAssessmentPcrs',
                                multiple: true,
                            }),
                        },
                        {
                            element: {
                                type: UiSchemaType.AUTOCOMPLETE_ASYNC,
                                path: 'wdpWorkSteps',
                                label: intl.formatMessage({ id: 'dimr.field.rpa.wdpWorkSteps' }),
                                readOnly: true, // For display only; not editable from the DIMR
                                typeProperties: {
                                    multiple: true,
                                    fetchOptions: async () => [],
                                    fetchUseCases: [FetchUseCase.ON_LOAD],
                                    getOptionLabel: (step: WdpWorkStepReferencedReadDTO) =>
                                        `${formatVersionedId(step.wdpMasterId, step.versionNumber)} - ${
                                            step.description
                                        }`,
                                },
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.LAYOUT_RESPONSIVE,
                                typeProperties: {
                                    direction: {
                                        xs: 'row',
                                    },
                                },
                                elements: [
                                    {
                                        element: {
                                            type: UiSchemaType.CHECKBOX,
                                            label: intl.formatMessage({ id: 'dimr.field.rpa.opDosimetryMandatory' }),
                                            path: 'operDosimetryMandatory',
                                        },
                                    },
                                    {
                                        element: {
                                            type: UiSchemaType.CHECKBOX,
                                            label: intl.formatMessage({
                                                id: 'document.field.extremityDosimetryMandatory',
                                            }),
                                            path: 'extremityDosimetryMandatory',
                                        },
                                    },
                                ],
                            },
                        },
                        {
                            element: prefilledLovSchema({
                                label: intl.formatMessage({ id: 'dimr.field.rpa.highestAreaClassification' }),
                                path: 'highestAreaClassification',
                                fetchOptions: getDimrAreaClassification,
                            }),
                        },
                        {
                            element: {
                                type: UiSchemaType.CUSTOM,
                                Component: EstimatedValuesAlert as any,
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.LAYOUT_RESPONSIVE,
                                typeProperties: {
                                    direction: {
                                        xs: 'column',
                                        md: 'row',
                                    },
                                },
                                elements: [
                                    {
                                        element: numberWithUnitSchemaFor(
                                            {
                                                label: intl.formatMessage({ id: 'dimr.field.rpa.avgEstDoseRate' }),
                                                path: 'avgEstDoseRate',
                                                unit: intl.formatMessage({ id: 'document.unit.microSievertPerHour' }),
                                                integer: false,
                                                signed: false,
                                            },
                                            intl
                                        ),
                                    },
                                    {
                                        element: numberWithUnitSchemaFor(
                                            {
                                                label: intl.formatMessage({
                                                    id: 'dimr.field.rpa.totalCollectiveWorkTime',
                                                }),
                                                path: 'totalCollectiveWorkTime',
                                                unit: intl.formatMessage({ id: 'document.unit.personHour' }),
                                                integer: false,
                                                signed: false,
                                            },
                                            intl
                                        ),
                                    },
                                ],
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.LAYOUT_RESPONSIVE,
                                typeProperties: {
                                    direction: {
                                        xs: 'column',
                                        md: 'row',
                                    },
                                },
                                elements: [
                                    {
                                        element: numberWithUnitSchemaFor(
                                            {
                                                label: intl.formatMessage({
                                                    id: 'dimr.field.rpa.maxIndividualWorkTime',
                                                }),
                                                path: 'maxIndividualWorkTime',
                                                unit: intl.formatMessage({ id: 'document.unit.hour' }),
                                                integer: false,
                                                signed: false,
                                            },
                                            intl
                                        ),
                                    },
                                    {
                                        element: numberWithUnitSchemaFor(
                                            {
                                                label: intl.formatMessage({ id: 'dimr.field.rpa.estCollectiveDose' }),
                                                path: 'estCollectiveDose',
                                                unit: intl.formatMessage({ id: 'document.unit.personMicroSievert' }),
                                                recipe: intl.formatMessage({ id: 'dimr.recipe.rpa.estCollectiveDose' }),
                                                integer: true,
                                                signed: false,
                                            },
                                            intl
                                        ),
                                    },
                                ],
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.LAYOUT_RESPONSIVE,
                                typeProperties: {
                                    direction: {
                                        xs: 'column',
                                        md: 'row',
                                    },
                                },
                                elements: [
                                    {
                                        element: numberWithUnitSchemaFor(
                                            {
                                                label: intl.formatMessage({
                                                    id: 'dimr.field.rpa.maxEstIndividualDose',
                                                }),
                                                path: 'maxEstIndividualDose',
                                                unit: intl.formatMessage({ id: 'document.unit.microSievert' }),
                                                recipe: intl.formatMessage({
                                                    id: 'dimr.recipe.rpa.maxEstIndividualDose',
                                                }),
                                                integer: true,
                                                signed: false,
                                            },
                                            intl
                                        ),
                                    },
                                    {
                                        element: numberWithUnitSchemaFor(
                                            {
                                                label: intl.formatMessage({ id: 'dimr.field.rpa.individualDoseAlarm' }),
                                                path: 'individualDoseAlarm',
                                                unit: intl.formatMessage({ id: 'document.unit.microSievert' }),
                                                integer: true,
                                                signed: false,
                                                recipe: intl.formatMessage(
                                                    { id: 'dimr.recipe.rpa.individualDoseAlarm' },
                                                    {
                                                        individualDose: 50,
                                                    }
                                                ),
                                            },
                                            intl
                                        ),
                                    },
                                ],
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.LAYOUT_RESPONSIVE,
                                typeProperties: {
                                    direction: {
                                        xs: 'column',
                                        md: 'row',
                                    },
                                },
                                elements: [
                                    {
                                        element: numberWithUnitSchemaFor(
                                            {
                                                label: intl.formatMessage({ id: 'dimr.field.rpa.maxEstDoseRate' }),
                                                path: 'maxEstDoseRate',
                                                unit: intl.formatMessage({ id: 'document.unit.microSievertPerHour' }),
                                                integer: true,
                                                signed: false,
                                            },
                                            intl
                                        ),
                                    },
                                    {
                                        element: numberWithUnitSchemaFor(
                                            {
                                                label: intl.formatMessage({
                                                    id: 'dimr.field.rpa.doseRateAlarmThreshold',
                                                }),
                                                path: 'doseRateAlarmThreshold',
                                                unit: intl.formatMessage({ id: 'document.unit.microSievertPerHour' }),
                                                integer: true,
                                                signed: false,
                                                recipe: intl.formatMessage(
                                                    { id: 'dimr.recipe.rpa.doseRateAlarmThreshold' },
                                                    { minMaxEstDoseRate: 2000, doseRate1: 100, doseRate2: 2000 }
                                                ),
                                            },
                                            intl
                                        ),
                                    },
                                ],
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.CHECKBOX,
                                label: intl.formatMessage({ id: 'dimr.field.rpa.contaminatingWorks' }),
                                path: 'contaminatingWorks',
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.LAYOUT_RESPONSIVE,
                                typeProperties: {
                                    direction: {
                                        xs: 'column',
                                        md: 'row',
                                    },
                                },
                                elements: [
                                    {
                                        element: numberWithUnitSchemaFor(
                                            {
                                                label: intl.formatMessage({ id: 'dimr.field.rpa.maxEstAirborneCont' }),
                                                path: 'maxEstAirborneCont',
                                                unit: intl.formatMessage({ id: 'document.unit.contaminationAirborne' }),
                                                integer: false,
                                                signed: false,
                                                recipe: intl.formatMessage(
                                                    { id: 'dimr.recipe.rpa.maxEstAirborneCont' },
                                                    { contaminationAirborne: '0.05' }
                                                ),
                                            },
                                            intl
                                        ),
                                    },
                                    {
                                        element: numberWithUnitSchemaFor(
                                            {
                                                label: intl.formatMessage({ id: 'dimr.field.rpa.maxEstSurfaceCont' }),
                                                path: 'maxEstSurfaceCont',
                                                unit: intl.formatMessage({ id: 'document.unit.contaminationSurface' }),
                                                integer: false,
                                                signed: false,
                                                recipe: intl.formatMessage(
                                                    { id: 'dimr.recipe.rpa.maxEstSurfaceCont' },
                                                    { contaminationSurface: 1 }
                                                ),
                                            },
                                            intl
                                        ),
                                    },
                                ],
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.CHECKBOX,
                                label: intl.formatMessage({ id: 'dimr.field.rpa.forceValues' }),
                                path: 'valuesForcedManual',
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.LAYOUT_RESPONSIVE,
                                typeProperties: {
                                    direction: {
                                        xs: 'column',
                                        md: 'row',
                                    },
                                },
                                elements: [
                                    {
                                        element: {
                                            type: UiSchemaType.INPUT,
                                            label: intl.formatMessage({ id: 'dimr.field.rpa.rpComments' }),
                                            path: 'rpComments',
                                            typeProperties: {
                                                multiline: true,
                                                highlighted: true,
                                            },
                                        },
                                    },
                                    {
                                        element: {
                                            type: UiSchemaType.INPUT,
                                            label: intl.formatMessage({ id: 'dimr.field.rpa.rsoComments' }),
                                            path: 'rsoComments',
                                            typeProperties: {
                                                multiline: true,
                                                highlighted: true,
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.FIELD_ARRAY,
                                path: 'historicalRadiologicalConditions',
                                element: uiSchemaHistoricalComments('dimr.field.rpa.radiologicalConditions', intl),
                                appendLabel: 'Not used',
                                disabled: true,
                                readOnly: true,
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.FIELD_ARRAY,
                                path: 'historicalRpRecommendations',
                                element: uiSchemaHistoricalComments('dimr.field.rpa.rpRsoRecommendations', intl),
                                appendLabel: 'Not used',
                                disabled: true,
                                readOnly: true,
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.CUSTOM,
                                Component: (props) => <RpAssessmentDoseReport {...(props as any)} />,
                            },
                        },
                        {
                            element: {
                                type: UiSchemaType.CUSTOM,
                                Component: (props) => (
                                    <RpAssessmentAttachments {...(props as any)} currentTask={currentTask} />
                                ),
                            },
                        },
                    ],
                },
            },
        },
    ],
});
