import { AxiosResponse } from 'axios';
import { buildPath } from '../../lib/url';
import { backendClient as client } from '../clients';
import { CdoSnapshotDTO, DocumentType } from '../dto';
import { GetManyUnpaged } from '../types';

const ADMIN_PATH = 'admin';

const RESOURCE_NAME = 'audit';

export const getDocumentAudit: GetManyUnpaged<
    { documentType: DocumentType; documentId: number; limit?: number },
    CdoSnapshotDTO<unknown>
> = async ({ documentType, documentId, limit, config }) => {
    return client
        .get(buildPath([ADMIN_PATH, RESOURCE_NAME, 'document', documentType, documentId]), {
            ...config,
            params: { limit },
        })
        .then((response: AxiosResponse) => response.data);
};
