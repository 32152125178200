export const copyToClipboard = async (text: string) => {
    if (navigator.clipboard) {
        // Legacy browsers and insecure HTTP protocols
        try {
            await navigator.clipboard.writeText(text); // Don't forget the `await`
            return true;
        } catch (e) {
            console.warn(e);
        }
    }
    return false;
};
