import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { copyToClipboard } from '../lib/clipboard';

export const useCopyToClipboard = () => {
    const intl = useIntl();
    return useCallback(
        (value: string) => {
            copyToClipboard(value).then((success) => {
                if (success) {
                    toast.info(intl.formatMessage({ id: 'common.copiedToClipboard' }));
                }
            });
        },
        [intl]
    );
};
