import { CloudDownload, Delete, Edit, Image, InsertDriveFile, Print } from '@mui/icons-material';
import {
    CircularProgress,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { getAttachment } from '../../api/attachments';
import { AttachmentDTO, AttachmentUploadDTO } from '../../api/dto';
import { PdfDialog } from '../PdfDialog';
import { DescriptionPopover } from './DescriptionPopover';

interface AttachmentListEntryProps {
    attachment: AttachmentDTO;
    onSelection: (attachment: AttachmentDTO) => void;
    onRemoval: (attachment: AttachmentDTO) => void;
    onUpdate: (newValue: AttachmentDTO) => void;
    updateAttachment: (args: { attachmentId: number; data: AttachmentUploadDTO }) => Promise<AttachmentDTO>;
    selected?: boolean;
    readOnly?: boolean;
}

export const AttachmentListEntry: React.FC<AttachmentListEntryProps> = ({
    attachment,
    onSelection,
    onRemoval,
    onUpdate,
    updateAttachment,
    selected = false,
    readOnly = false,
}) => {
    const intl = useIntl();
    const downloadFile = ({ data, fileName }: { data: any; fileName: string }) => {
        const href = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    };

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [openPdfDialog, setOpenPdfDialog] = useState(false);

    const handleOpenPdfDialog = () => {
        setOpenPdfDialog(true);
    };

    const handleClosePdfDialog = () => {
        setOpenPdfDialog(false);
    };

    const {
        mutate: mutateUpdateAttachment,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
    } = useMutation(updateAttachment, {
        onSuccess: (data) => {
            setAnchorEl(null);
            onUpdate(data);
        },
    });
    // FIXME: should use `useQuery` instead of `useMutation`
    const { mutate: mutateGetAttachment, isLoading: isGetLoading } = useMutation(getAttachment, {
        onSuccess: (data) => downloadFile({ data, fileName: attachment.originalFileName }),
    });

    const { mutate: mutateGetPdf, isLoading: isGetPdfLoading } = useMutation(getAttachment);

    const fetchPdf = (): Promise<Blob> => {
        return new Promise((resolve) => {
            mutateGetPdf(
                { id: attachment.id },
                {
                    onSuccess: (data) => resolve(data),
                }
            );
        });
    };

    return (
        <ListItem
            secondaryAction={
                <>
                    {!readOnly && (
                        <>
                            <Tooltip
                                title={intl.formatMessage({ id: 'document.attachment.tooltip.delete' })}
                                placement="top"
                            >
                                <IconButton onClick={() => onRemoval(attachment)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title={intl.formatMessage({ id: 'document.attachment.tooltip.edit' })}
                                placement="top"
                            >
                                <IconButton
                                    onClick={(event) => {
                                        setAnchorEl(event.currentTarget);
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                    <Tooltip title={intl.formatMessage({ id: 'document.attachment.tooltip.download' })} placement="top">
                        <IconButton
                            onClick={() =>
                                mutateGetAttachment({
                                    id: attachment.id,
                                })
                            }
                            disabled={isGetLoading}
                        >
                            <CloudDownload sx={{ opacity: isGetLoading ? 0 : undefined }} />
                            {isGetLoading && (
                                <CircularProgress color="inherit" size={20} sx={{ position: 'absolute', left: 10 }} />
                            )}
                        </IconButton>
                    </Tooltip>
                    {attachment.mimeType === 'application/pdf' && (
                        <Tooltip title={intl.formatMessage({ id: 'document.action.preview' })} placement="top">
                            <IconButton onClick={handleOpenPdfDialog} disabled={isGetPdfLoading}>
                                <Print />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            }
            disablePadding
        >
            <ListItemButton onClick={() => onSelection(attachment)} selected={selected}>
                <ListItemIcon>
                    {attachment.mimeType?.startsWith('image/') ? <Image /> : <InsertDriveFile />}
                </ListItemIcon>
                <ListItemText primary={attachment.originalFileName} />
            </ListItemButton>

            <DescriptionPopover
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                onSubmit={(newDescription) =>
                    mutateUpdateAttachment({ attachmentId: attachment.id, data: { description: newDescription } })
                }
                initialValue={attachment.description ?? ''}
                loading={isUpdateLoading}
                error={isUpdateError}
            />
            <PdfDialog
                queryKey={[attachment.originalFileName, attachment.id]}
                fetchPdf={fetchPdf}
                open={openPdfDialog}
                onClose={handleClosePdfDialog}
            />
        </ListItem>
    );
};
